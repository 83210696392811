import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import { Autocomplete, Alert, AlertTitle, Checkbox, ListItemText, TextField, Select, MenuItem, InputLabel, FormGroup, Radio, RadioGroup, FormControlLabel} from "@mui/material";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs from 'dayjs';

import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Client from '../../client/learners'; 
import TutorClient from '../../client/tutor'; 

const mathsTopics = [
    'Whole Numbers',
    'Common Fractions',
    'Integers',
    'Exponents',
    'Numeric & Geometric Patterns',
    'Algeraic Equations',
    'Algeraic Expressions',
    'Graphs',
    'Geometry of Straight Lines',
    'Geometry of 2D Shapes',
    'Transformation Geometry',
    'Functions & Relationships',
    'Area & Permimeter of 2D Shapes',
    'Surface and Volume',
    'Theorem of Pythagoras',
    'Revision',
  ];

function StepOne(props){

    //variables from parent component
    const is_active = props.is_active;
    const next_step = props.next_step;
    const on_next_step = props.on_next_step;
    const set_attendance_id = props.set_attendance_id;
    const set_attendance_obj = props.set_attendance_obj;
    
    //data related variables
    const [classDate, setDate] = useState('');
    const [attClass, setClass] = useState('');
    const [tutors, setTutors] = useState([]);
    const [classTopics, setClassTopics] = useState([]);

    //ui related variables
    const [classOptions, setClassOptions] = useState([]);
    const [tutorOptions, setTutorOptions] = useState([]);

    const [showError, setShowError] = useState(false);
    const [loadingScreen, setLoadingScreen] = useState(false);
    const [loadingClass, setLoadingClass] = useState(false);
    const [loadingTutors, setLoadingTutor] = useState(false);

    //load stored tutor details
    const tutor_id = sessionStorage.getItem("user_id");
    const tutor_token = sessionStorage.getItem("user_token");

    //fetch classes
    useEffect(()=>{
        loadClasses();
        loadTutors();
    }, []);

    const loadClasses = async()=>{
        setLoadingClass(true);
        const classes_data = await Client.GetClass({});
        if(classes_data && classes_data.data){
            const classes = classes_data.data;
            const classList = [];
            classes.forEach((c)=>{
                classList.push({
                    key: c.name,
                    value: c._id,
                });
            });
            setClassOptions([
                {key: 'Select Class', value:'null'},
                ...classList
            ]);
        }
        else{
            //display error
            console.log('EISH. Couldnt load classes');
        }
        setLoadingClass(false);
    }
    const loadTutors = async()=>{
        setLoadingTutor(true);
        const tutor_data = await TutorClient.GetTutors({});
        if(tutor_data && tutor_data.data){
            const tutors = tutor_data.data;
            const tutorList = [];
            tutors.forEach((c)=>{
                let name = '';
                if(c.name && c.surname){
                    name=c.name+" "+c.surname;
                }
                tutorList.push({
                    key: name,
                    value: c._id,
                });
            });
            setTutorOptions([
                {key: 'Select Tutor', value:'null'},
                ...tutorList
            ]);
        }
        else{
            //display error
            console.log('EISH. Couldnt load tutors');
        }
        setLoadingTutor(false);
    }

    async function nextStep(e){        
        setLoadingScreen(true);

        if(!tutors.includes(tutor_id)){
            tutors.push(tutor_id);
        }

        const new_att = {
            date: classDate,
            class: attClass,
            tutors: tutors,
            class_topics: classTopics
        }
        //store values on server
        const outcome = await Client.CreateAttendance(new_att);
        setLoadingScreen(false);
        if(!outcome.error){
            //push updated values to parent component
            set_attendance_id(outcome.data.data._id);
            set_attendance_obj({class: attClass});
            //move to next component/section
            on_next_step(next_step);
        }
        else{
            setShowError(true);
        }
        
    }

    function handleTutorSelection(e){
        const {
        target: { value },
        } = e;

        const tutors = typeof value === 'string' ? value.split(',') : value;
        setTutors(tutors);
    }

    function handleClassTopics(e){
        const {
            target: { value },
        } = e;

        const topics = typeof value === 'string' ? value.split(',') : value;
        setClassTopics(topics);
    }
    function activeDays(date){
        const currentDate = new Date(date);
        return currentDate.getDay()!==6 && currentDate.getDay()!==4 && currentDate.getDay()!==3;
    }
    return <div style={{display: is_active?'block':'none'}}>
            <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDatePicker shouldDisableDate={activeDays} slotProps={{ actionBar: { actions: [] } }} value={classDate} onChange={(date)=>setDate(date)} maxDate={dayjs()}/>
                </LocalizationProvider>
            </div>
            <div>
                <InputLabel id="class-label">Class</InputLabel>
                <Select
                    labelId="class-label"
                    id="class-select"
                    label="Class"
                    onChange={(e)=>{setClass(e.target.value)}}
                >
                    {classOptions.map((c)=>(
                    <MenuItem key={c.key} value={c.value}>
                        <ListItemText primary={c.key} />
                    </MenuItem>
                    )
                    )}
                </Select>
            </div>
            <div>
                    <InputLabel id="class-topics-label">Topics Covered in Session</InputLabel>
                    <Select
                        labelId="class-topics-label"
                        id="class-topics-covered-select"
                        label="Topics Covered in Session" 
                        multiple
                        defaultValue={""}
                        value={classTopics}
                        onChange={handleClassTopics}
                        renderValue={(selected) => selected.join(', ')}
                    >
                        {mathsTopics.map((topic) => (
                            <MenuItem key={topic} value={topic}>
                                <Checkbox checked={classTopics.indexOf(topic) > -1} />
                                <ListItemText primary={topic} />
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            <div style={{display: !loadingClass&&!loadingTutors?'block':'none'}} >
                <InputLabel id="tutors-label">Any Other Tutors Present?</InputLabel>
                <Select
                    labelId="tutors-label"
                    id="tutors-select"
                    label="Any Other Tutors Present?" 
                    multiple
                    defaultValue={""}
                    value={tutors}
                    onChange={handleTutorSelection}
                    renderValue={(selected) => selected.join(', ')}
                >
                    {tutorOptions.map((tut) => (
                        <MenuItem key={tut.key} value={tut.value}>
                            <Checkbox checked={tutors.indexOf(tut.value) > -1} />
                            <ListItemText primary={tut.key} />
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <div>
                <LinearProgress style={{display: loadingClass&&loadingTutors?'block':'none'}} color="primary"/>
            </div>
            <Alert severity="error" className='alert-box' style={{display: showError?'block':'none'}}>
                <AlertTitle><strong>Error</strong></AlertTitle>
                Error creating attendance. Please refresh page and try again
            </Alert>
            <div className="heel" style={{display: !loadingClass&&!loadingTutors?'block':'none'}} >
                <Button variant="contained" size="large" onClick={nextStep}>
                    Next
                </Button>
        </div>
    </div>
}

function StepTwo(props){
    //variables from parent component
    const is_active = props.is_active;
    const next_step = props.next_step;
    const on_next_step = props.on_next_step;
    const attendance_id = props.attendance;
    const set_attendance = props.set_attendance;
    const attendance_obj = props.attendance_obj;
    
    //data related variables
    const [pre_topic_test, setPreTopicTest] = useState(false);
    const [pre_topic_total, setPreTopicTotal] = useState(0);
    const [pre_test_topics, setPreTestTopics] = useState([]);

    const [post_topic_test, setPostTopicTest] = useState(false);
    const [post_topic_total, setPostTopicTotal] = useState(0);
    const [post_test_topics, setPostTestTopics] = useState([]);

    const [review_test, setReviewTest] = useState(false);
    const [review_total, setReviewTotal] = useState(0);
    const [review_test_topics, setReviewTestTopics] = useState([]);

    //ui variables
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);

    async function nextStep(e){
        //check if any tests were done
        if(!post_topic_test && !pre_test_topics && !review_test){
            //move to next component/section
            on_next_step(next_step);
            return;
        }
        //load learners test marks
        const updated_att = {};

        updated_att.class = attendance_obj.class;

        if(post_topic_test){
            updated_att.post_topic_test = post_topic_test;
            updated_att.post_test_topics = post_test_topics;
            updated_att.post_topic_total = post_topic_total;
        }
        else{
            updated_att.post_topic_test = post_topic_test;
        }
        if(pre_topic_test){
            updated_att.pre_topic_test = pre_topic_test;
            updated_att.pre_test_topics = pre_test_topics;
            updated_att.pre_topic_total = pre_topic_total;
        }
        else{
            updated_att.pre_topic_test = pre_topic_test;
        }
        if(review_test){
            updated_att.review_test = review_test;
            updated_att.review_test_topics = review_test_topics;
            updated_att.review_total = review_total;
        }
        else{
            updated_att.review_test = review_test;
        }

        //store values on server
        const outcome = await Client.UpdateAttendance(attendance_id, updated_att);
        setLoading(false);
        if(outcome){
            //push updated values to parent component
            set_attendance(updated_att);
            //move to next component/section
            on_next_step(next_step);
        }
        else{
            setShowError(true);
        }
        on_next_step(next_step);
    }
    function handleReviewTopics(e){
        const {
            target: { value },
        } = e;

        const topics = typeof value === 'string' ? value.split(',') : value;
        setReviewTestTopics(topics);
    }

    function handlePostTopics(e){
        const {
            target: { value },
        } = e;

        const topics = typeof value === 'string' ? value.split(',') : value;
        setPostTestTopics(topics);
    }

    function handlePreTopics(e){
        const {
            target: { value },
        } = e;

        const topics = typeof value === 'string' ? value.split(',') : value;
        setPreTestTopics(topics);
    }
    return <>
    {is_active?(<div style={{display: is_active?'block':'none'}}>
        <div>
            <Typography variant="h6" component="div">
                Was there a Pre-Topic Test?
            </Typography>
            <RadioGroup
                value={pre_topic_test}
                name="radio-buttons-group"
            >
                <FormControlLabel value={true} onClick={()=>{setPreTopicTest(true)}} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} onClick={()=>{setPreTopicTest(false)}} control={<Radio />} label="No" />
            </RadioGroup>
            <div style={{display: pre_topic_test?'block':'none'}}>
                <TextField
                    id="pre-total"
                    label="Total Marks"
                    type="number"
                    color="secondary"
                    helperText="How many marks is the test out of"
                    onChange={(e)=>{setPreTopicTotal(e.target.value)}}
                    />
                 <div>
                    <InputLabel id="pre-topics-label">Topics Covered in Pre-Topic Test</InputLabel>
                    <Select
                        labelId="pre-topics-label"
                        id="pre-topics-covered-select"
                        label="Topics Covered in Pre-Topic Test" 
                        multiple
                        defaultValue={""}
                        value={pre_test_topics}
                        onChange={handlePreTopics}
                        renderValue={(selected) => selected.join(', ')}
                    >
                        {mathsTopics.map((topic) => (
                            <MenuItem key={topic} value={topic}>
                                <Checkbox checked={pre_test_topics.indexOf(topic) > -1} />
                                <ListItemText primary={topic} />
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </div>
        </div>
        <div>
            <Typography variant="h6" component="div">
                Was there a Post-Topic Test?
            </Typography>
            <RadioGroup
                value={post_topic_test}
                name="radio-buttons-group"
            >
                <FormControlLabel value={true}  onClick={()=>{setPostTopicTest(true)}} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} onClick={()=>{setPostTopicTest(false)}} control={<Radio />} label="No" />
            </RadioGroup>
            <div style={{display: post_topic_test?'block':'none'}}>
                <TextField
                    id="post-total"
                    label="Total Marks"
                    helperText="How many marks is the test out of"
                    type="number"
                    color="secondary" 
                    onChange={(e)=>{setPostTopicTotal(e.target.value)}}
                    />

                    <div>
                        <InputLabel id="post-topics-label">Topics Covered in Post-Topic Test</InputLabel>
                        <Select
                            labelId="post-topics-label"
                            id="post-topics-select"
                            label="Topics Covered in Post-Topic Test" 
                            multiple
                            defaultValue={""}
                            value={post_test_topics}
                            onChange={handlePostTopics}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {mathsTopics.map((topic) => (
                                <MenuItem key={topic} value={topic}>
                                    <Checkbox checked={post_test_topics.indexOf(topic) > -1} />
                                    <ListItemText primary={topic} />
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
            </div>
        </div>
        <div>
            <Typography variant="h6" component="div">
                Was there a Review Test?
            </Typography>
            <RadioGroup
                value={review_test}
                name="radio-buttons-group"
            >
                <FormControlLabel value={true}  onClick={()=>{setReviewTest(true)}} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} onClick={()=>{setReviewTest(false)}} control={<Radio />} label="No" />
            </RadioGroup>
            <div style={{display: review_test?'block':'none'}}>
                <TextField
                    id="review-total"
                    label="Total Marks"
                    helperText="How many marks is the test out of"
                    type="number"
                    color="secondary" 
                    onChange={(e)=>{setReviewTotal(e.target.value)}}
                    />


                    <div>
                        <InputLabel id="topics-comfortable-label">Topics Covered in Review Test</InputLabel>
                        <Select
                            labelId="topics-comfortable-label"
                            id="review-topics-covered-select"
                            label="Topics Covered in Review Test" 
                            multiple
                            defaultValue={""}
                            value={review_test_topics}
                            onChange={handleReviewTopics}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {mathsTopics.map((topic) => (
                                <MenuItem key={topic} value={topic}>
                                    <Checkbox checked={review_test_topics.indexOf(topic) > -1} />
                                    <ListItemText primary={topic} />
                                </MenuItem>
                            ))}
                        </Select>
                    </div>

            </div>
        </div>
        <div className="heel">
            <Button variant="contained" size="large" onClick={nextStep}>
                Next
            </Button>
        </div>
    </div>):null}
    </>
}

function StepThree(props){
    //variables from parent component
    const is_active = props.is_active;
    const next_step = props.next_step;
    const on_next_step = props.on_next_step;
    const attendance_id = props.attendance;
    const set_attendance = props.set_attendance;
    const attendance_obj = props.attendance_obj;
    console.log('ATT OBJ(3): ', attendance_obj)

    //data related variables
    const [presentLearners, setPresentLearners] = useState([]);

    //ui related variables
    const [learnerOptions, setLearnerOptions] = useState([]);

    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);


    //fetch learners
    useEffect(()=>{
            loadLearners();
    }, [props.is_active]);

    const loadLearners = async()=>{

        if(!is_active){
            console.log('STEP 3 is not active')
        }
        setLoading(true);
        const learner_data = await Client.GetLearners({class: attendance_obj.class});
        console.log('ATT: ',learner_data);
        if(learner_data && learner_data.data){
            const learners = learner_data.data;
            const learnerList = [];
            learners.forEach((learner)=>{
                learnerList.push({
                    key: learner.name+"   "+learner.surname,
                    value: learner._id,
                });
            });
            setLearnerOptions(
                learnerList
            );
        }
        else{
            //display error
            console.log('EISH. Couldnt load learners');
        }
        setLoading(false);
    }

    async function handleLearnerSelect(e){
        const {
            target: { value },
          } = e;
          let updatedList=[];
          if(presentLearners.indexOf(value) > -1){
            updatedList=presentLearners.filter((id=>id!==value));
          }else{
            updatedList=[value,...presentLearners];
          }
          setPresentLearners(updatedList);
    }

    async function nextStep(e){
        const updated_att = {};
        if(presentLearners.length>0){
            updated_att.learners = presentLearners.map(learner => ({ learner }));

            //store values on server
            const outcome = await Client.UpdateAttendance(attendance_id, updated_att);
            setLoading(false);
            if(outcome){
                //push updated values to parent component
                const memory_att = attendance_obj;
                memory_att.learners = updated_att.learners;
                set_attendance(memory_att);
                //move to next component/section
                on_next_step(next_step);
            }
            else{
                setShowError(true);
            }
        }
    }


    return <>
    {is_active?(<div style={{display: is_active?'block':'none'}}>
            <div>
                <InputLabel id="learners-label">Learners Present</InputLabel>
                <FormGroup>
                    {learnerOptions.map((learner)=>(
                        <FormControlLabel control={<Checkbox value={learner.value} onChange={handleLearnerSelect} checked={presentLearners.indexOf(learner.value) > -1}/>} label={learner.key} />
                    ))}

                </FormGroup>
            </div>
            <div>
                <LinearProgress style={{display: loading?'block':'none'}} color="primary"/>
            </div>
            <Alert severity="error" className='alert-box' style={{display: showError?'block':'none'}}>
                <AlertTitle><strong>Error</strong></AlertTitle>
                Error featching learners. Please refresh page and try again
            </Alert>
            <div className="heel">
                <Button variant="contained" size="large" onClick={nextStep}>
                    Next
                </Button>
        </div>
    </div>):null}
    </>
}


function StepFour(props){
    const is_active = props.is_active;
    const next_step = props.next_step;
    const on_next_step = props.on_next_step;
    const attendance_id = props.attendance;
    const set_attendance = props.set_attendance;
    const attendance_obj = props.attendance_obj;

    console.log('ATT OBJ(4): ', attendance_obj)
    //data related variables
    const [missingLearners, setMissingLearners] = useState([]);

    //ui related variables
    const [learnerOptions, setLearnerOptions] = useState([]);

    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);


    //fetch learners
    const loadLearners = async()=>{
        setLoading(true);
        const learner_data = await Client.GetLearners({});
        if(learner_data && learner_data.data){
            const learners = learner_data.data;
            const learnerList = [];
            learners.forEach((learner)=>{
                learnerList.push({
                    key: learner.name+" "+learner.surname,
                    value: learner._id,
                });
            });
            setLearnerOptions(
                learnerList
            );
        }
        else{
            //display error
            console.log('EISH. Couldnt load learners');
        }
        setLoading(false);
    }
    useEffect(()=>{
        loadLearners();
    }, []);

    async function handleLearnerSelect(e, selected_learners){
          const updatedList=selected_learners;
          setMissingLearners(updatedList);
    }

    async function nextStep(e){
        if(missingLearners.length<=0){
            //move to next component/section
            on_next_step(next_step);
            return;
        }
        const learner_ids =  [];
        missingLearners.forEach(learner=>{
            const id = learnerOptions.filter(value=>{return value.key===learner});
            if(id.length){
                learner_ids.push({learner: id[0].value});
            }
        })
        const updated_att = {};
        if(learner_ids.length>0){
            updated_att.learners = learner_ids;
            const outcome = await Client.UpdateAttendance(attendance_id, updated_att);
            setLoading(false);
            if(outcome){
                //push updated values to parent component
                const memory_att = attendance_obj;
                memory_att.learners = [...attendance_obj.learners, ...updated_att.learners];
                set_attendance(memory_att);

                //move to next component/section
                on_next_step(next_step);
            }
            else{
                setShowError(true);
            }
        }
    }


    return <>
    {is_active?(<div style={{display: is_active?'block':'none'}}>
        <div>
            <Typography variant="h6" component="div">
                Any Student not in the list? Search for there names below:
            </Typography>
        </div>
        <div>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={learnerOptions.map((option) => option.key)}
                multiple={true}
                sx={{ width: 300 }}
                onChange={handleLearnerSelect}
                noOptionsText="No learners found matching search"
                renderInput={(params) => <TextField {...params} label="Learner" />}
                />
        </div>
        <div>
            <LinearProgress style={{display: loading?'block':'none'}} color="primary"/>
        </div>
        <Alert severity="error" className='alert-box' style={{display: showError?'block':'none'}}>
            <AlertTitle><strong>Error</strong></AlertTitle>
            Error featching learners. Please refresh page and try again
        </Alert>
        <div className="heel">
            <Button variant="contained" size="large" onClick={nextStep}>
                Next
            </Button>
        </div>
    </div>
    ):null}
    </>
}

function StepFive(props){
    
    //variables from parent component
    const is_active = props.is_active;
    console.log('STEP 5: ', is_active)
    // while(!is_active){}//ensure component is active before continuing
    const next_step = props.next_step;
    const on_next_step = props.on_next_step;
    const attendance_id = props.attendance;
    const attendance_obj = props.attendance_obj;
    console.log('ATT OBJ (5): ', attendance_obj)

    //data related variables
    const [preTopicMarks, setPreTopicMarks] = useState([]);
    const [postTopicMarks, setPostTopicMarks] = useState([]);
    const [reviewMarks, setReviewMarks] = useState([]);

    //ui related variables
    const [learnerOptions, setLearnerOptions] = useState([]);

    const [showError, setShowError] = useState(false);
    const [showValidationError, setValidationError] = useState(false);
    const [loading, setLoading] = useState(false);

    //fetch learners
    const loadLearners = async()=>{
        //if no tests complete process
        if(is_active && !attendance_obj.post_topic_test && !attendance_obj.pre_topic_test && !attendance_obj.review_test){
            on_next_step(next_step);
            return;
        }
        setLoading(true);
        console.log('LOADING LEARNERS: ', attendance_obj);
        if(attendance_obj && attendance_obj.learners && attendance_obj.learners.length>0){
            const learner_filter = attendance_obj.learners.map(learner=>learner.learner);
            const filter = {};
            filter.multiple = true;
            filter.learners = learner_filter;

            const learner_data = await Client.GetLearners(filter);
            if(learner_data && learner_data.data){
                const learners = learner_data.data;
                const learnerList = [];
                learners.forEach((learner)=>{
                    learnerList.push({
                        fullname: learner.name+" "+learner.surname,
                        id: learner._id,
                    });
                });
                setLearnerOptions(
                    learnerList
                );
                console.log('data list: ', learnerList);
            }
            else{
                //display error
                console.log('EISH. Couldnt load learners');
            }
        }
        
        setLoading(false);
    }
    

    useEffect(()=>{
        if(is_active){
            loadLearners();
        }
    }, [props.is_active]);

    async function handlePostTestMarks(e, learner_id){
        const {
            target: { value },
          } = e;
        const updated_marks = [];
        const learner_mark = {learner: learner_id, test_type: 'post_topic_test', total_marks: value};
        let learner_mark_exists=false;
        if(postTopicMarks.length>0){
            postTopicMarks.forEach(marks=>{
                if(learner_mark.learner===marks.learner){
                    learner_mark_exists=true;
                    marks.total_marks=learner_mark.total_marks;
                }
                updated_marks.push(marks);
                return;
            })
        }
        if(!learner_mark_exists){
            updated_marks.push(learner_mark);
        }
        setPostTopicMarks(updated_marks);
    }
    async function handlePreTestMarks(e, learner_id){
        const {
            target: { value },
          } = e;
        const updated_marks = [];
        const learner_mark = {learner: learner_id, test_type: 'pre_topic_test', total_marks: value};
        let learner_mark_exists=false;
        if(preTopicMarks.length>0){
            preTopicMarks.forEach(marks=>{
                if(learner_mark.learner===marks.learner){
                    learner_mark_exists=true;
                    marks.total_marks=learner_mark.total_marks;
                }
                updated_marks.push(marks);
            })
        }
        if(!learner_mark_exists){
            updated_marks.push(learner_mark);
        }
        setPreTopicMarks(updated_marks);
  }

  async function handleReviewTestMarks(e, learner_id){
        const {
            target: { value },
        } = e;
        const updated_marks = [];
        const learner_mark = {learner: learner_id,  total_marks: value};
        let learner_mark_exists=false;
        if(reviewMarks.length>0){
            reviewMarks.forEach(marks=>{
                if(learner_mark.learner===marks.learner){
                    learner_mark_exists=true;
                    marks.total_marks=learner_mark.total_marks;
                }
                updated_marks.push(marks);
            });
        }
        if(!learner_mark_exists){
            updated_marks.push(learner_mark);
        }
        setReviewMarks(updated_marks);
    }
    async function nextStep(e){
        setLoading(true);
        setValidationError(false);
        console.log('NEXT: ',postTopicMarks.length ,' OTHER: ', learnerOptions);
        const post_test_valid = attendance_obj.post_topic_test ? (postTopicMarks.length === learnerOptions.length) : true;
        const pre_test_valid = attendance_obj.pre_topic_test ? (preTopicMarks.length === learnerOptions.length) : true;
        const review_test_valid = attendance_obj.review_test ? (reviewMarks.length === learnerOptions.length) : true;
        console.log('POST:', post_test_valid)
        console.log('PRE:', pre_test_valid)
        console.log('REV:', review_test_valid)
        if(!post_test_valid || !pre_test_valid || !review_test_valid){
            console.log('MOT VALID');
            setValidationError(true);
            return;
        }
        const learner_mark_list = [];
        learnerOptions.forEach(learner=>{
            const learner_mark = {};
            learner_mark.learner = learner.id;
            if(attendance_obj.post_topic_test){
                learner_mark.post_topic_test_mark = postTopicMarks.find(mark=>mark.learner===learner.id).total_marks;
            }
            if(attendance_obj.pre_topic_test){
                learner_mark.pre_topic_test_mark = preTopicMarks.find(mark=>mark.learner===learner.id).total_marks;
            }
            if(attendance_obj.review_test){
                learner_mark.review_test_mark = reviewMarks.find(mark=>mark.learner===learner.id).total_marks;
            }
            learner_mark_list.push(learner_mark);
        })
        

        console.log('E: ', learner_mark_list)
        const updated_att = {};
        updated_att.learners = learner_mark_list;
        const outcome = await Client.UpdateAttendance(attendance_id, updated_att);
        setLoading(false);
        if(outcome){
            //move to next component/section
            on_next_step(next_step);
        }
        else{
            setShowError(true);
        }
    }


    return <>
        {is_active?(
        <div style={{display: is_active?'block':'none'}}>
        <div style={{display: attendance_obj.pre_topic_test?'block':'none'}}>
            <div>
                <Typography variant="h6" component="div">
                    Pre-Topic Test
                </Typography>
            </div>
            <FormGroup>
                {learnerOptions.map((learner, index)=>(<>
                    <InputLabel id={'pre-test-index'+index}>{learner.fullname}</InputLabel>
                    <TextField label="Mark" variant='outlined' onChange={(e)=>handlePreTestMarks(e, learner.id)}/>
                </>))}

            </FormGroup>
        </div>
        <div style={{display: attendance_obj.post_topic_test?'block':'none'}}>
            <div>
                <Typography variant="h6" component="div">
                    Post-Topic Test
                </Typography>
            </div>
            <FormGroup>
                {learnerOptions.map((learner, index)=>(<>
                    <InputLabel id={'post-test-index'+index}>{learner.fullname}</InputLabel>
                    <TextField label="Mark" variant='outlined' onChange={(e)=>handlePostTestMarks(e, learner.id)}/>
                </>))}
            </FormGroup>
        </div>
        <div style={{display: attendance_obj.review_test?'block':'none'}}>
            <div>
                <Typography variant="h6" component="div">
                    Review Test
                </Typography>
            </div>
            <FormGroup>
                {learnerOptions.map((learner, index)=>(<>
                    <InputLabel id={'review-test-index'+index}>{learner.fullname}</InputLabel>
                    <TextField label="Mark" variant='outlined' onChange={(e)=>handleReviewTestMarks(e, learner.id)}/>
                </>))}

            </FormGroup>
        </div>
        <div>
            <LinearProgress style={{display: loading?'block':'none'}} color="primary"/>
        </div>
        <Alert severity="error" className='alert-box' style={{display: showError?'block':'none'}}>
            <AlertTitle><strong>Error</strong></AlertTitle>
            Error featching learners. Please refresh page and try again
        </Alert>

        <Alert severity="error" className='alert-box' style={{display: showValidationError?'block':'none'}}>
            <AlertTitle><strong>Validation Failure</strong></AlertTitle>
            Some learner(s) were not added to all tests
        </Alert>
        <div className="heel">
            <Button variant="contained" size="large" onClick={nextStep}>
                Next
            </Button>
        </div>
    </div>
        ):<></>}
</>
}

function NewAttendance(){
    const [active_step, setActiveStep] = useState('step_one');
    const [attendance_id, setAttendanceID] = useState('');
    const [current_attendance_obj, setAttendanceObj] = useState({});

    const navigate = useNavigate();
    function goHome(){
        navigate('/tutor/home');
    }
    
    return <>
            <Card>
                <Box margin={10}>
                    <Stack spacing={2}>
                        <Typography variant="h5" component="div">
                            Capture Attendance
                        </Typography>

                        <StepOne 
                            is_active={active_step==="step_one"} 
                            next_step="step_two" 
                            on_next_step={setActiveStep} 
                            set_attendance_id={setAttendanceID}
                            set_attendance_obj={setAttendanceObj}/>

                        <StepTwo 
                            is_active={active_step==="step_two"} 
                            next_step="step_three" 
                            on_next_step={setActiveStep} 
                            attendance={attendance_id}
                            attendance_obj={current_attendance_obj}
                            set_attendance={setAttendanceObj} />

                        <StepThree 
                            is_active={active_step==="step_three"} 
                            next_step="step_four" 
                            on_next_step={setActiveStep}
                            attendance={attendance_id}
                            attendance_obj={current_attendance_obj}
                            set_attendance={setAttendanceObj}/>

                        <StepFour 
                            is_active={active_step==="step_four"} 
                            next_step="step_five" 
                            on_next_step={setActiveStep}
                            attendance={attendance_id}
                            attendance_obj={current_attendance_obj}
                            set_attendance={setAttendanceObj}/>

                        <StepFive 
                            is_active={active_step==="step_five"} 
                            next_step="" 
                            on_next_step={goHome}
                            attendance={attendance_id}
                            attendance_obj={current_attendance_obj}/>
                    </Stack>
                </Box>
            </Card>
            
        </>
}

function Attendance() {
    const render = <NewAttendance/>
    
    return (
        <div>
            {alert}
            <Box 
                component="form"
                sx={{
                '& .MuiTextField-root': { m: 2, width: '35ch' },
                }}
            noValidate
            autoComplete="off"
            >
                {render}
                <a
                className="App-link"
                href="https://reactjs.org"
                target="_blank"
                rel="noopener noreferrer"
                >
                </a>
            </Box>
        </div>
    )
}

export default Attendance;