import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import { Checkbox, ListItemText, TextField, Alert, AlertTitle, Select, MenuItem, InputLabel} from "@mui/material";

import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import Client from '../../client/auth'; 
import ClientTutor from '../../client/tutor'; 

let render = null;

const mathsTopics = [
    'Whole Numbers',
    'Common Fractions',
    'Integers',
    'Exponents',
    'Numeric & Geometric Patterns',
    'Algeraic Equations',
    'Algeraic Expressions',
    'Graphs',
    'Geometry of Straight Lines',
    'Geometry of 2D Shapes',
    'Transformation Geometry',
    'Functions & Relationships',
    'Area & Permimeter of 2D Shapes',
    'Surface and Volume',
    'Theorem of Pythagoras',
    'Revision',
  ];

function ProfileDetails(){
    //create nav function
    const navigate = useNavigate();
    //declare variables
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    //function to process login button click
    async function handleTutorLogin(e){
        e.preventDefault();
        setShowError(false);
        setLoading(true);
        const outcome = await Client.LoginTutor(username, password);
        setLoading(false);
        if(outcome){
            navigate('/tutor/home');
        }
        else{
            setShowError(true);
        }
    }
    //components displayed on screem
    return <div>
        <Alert severity="error" className='alert-box' style={{display: showError?'block':'none'}}>
            <AlertTitle><strong>Error</strong></AlertTitle>
            Credentials provided do not work try again
        </Alert>
        <Card  sx={{minWidth: 350}}>
                <Box margin={10}>
                    <Stack spacing={2}>
                        <Typography variant="h3" component="div">
                            Mihandzu Tutor Login
                        </Typography>
                        <div>
                            <LinearProgress style={{display: loading?'block':'none'}} color="primary"/>
                        </div>
                        <div>
                            <TextField
                            id="tutor-username"
                            label="Username"
                            type="text"
                            color="secondary" 
                            value={username}
                            onChange={(e)=>{setUsername(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="tutor-password"
                            label="Password"
                            type="password"
                            color="secondary"
                            value={password}
                            onChange={(e)=>{setPassword(e.target.value)}}
                            />
                        </div>
                        <div className="heel">
                            <Button disabled={loading} variant="contained" size="large" onClick={handleTutorLogin}>
                                Log In
                            </Button>
                        </div>
                        <div>
                            <Link
                                className="App-link"
                                rel="noopener noreferrer"
                                to="/tutor/auth/apply"
                                >
                                    No Login Details? Apply Here
                            </Link>
                        </div>
                    </Stack>
                </Box>
            </Card></div>
}

function UpdateDetails(){
    //create nav function
    const navigate = useNavigate();
    //declare variables
    const [fullname, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [username, setUsername] = useState('');
    const [cellphone, setCell] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [university, setUniversity] = useState('');
    const [institution, setInstitution] = useState('');
    const [tertiaryLevel, setTertiaryLevel] = useState('');
    const [courseStudying, setStudying] = useState('');
    const [gradeTutoring, setGradeTutoring] = useState('');
    const [subjectTutoring, setSubjectTutoring] = useState([]);
    const [mathsTopicsComfortable, setMathTopicsComfortable] = useState([]);
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    
    //fetch profile
    useEffect(()=>{
        loadProfile()
    }, []);

    const loadProfile = async()=>{
        const profile_data = await ClientTutor.GetTutorByID('65acc70777b4c42017257e14');
        if(profile_data && profile_data.data){
            const profile = profile_data.data;
            console.log('PROFILE: ', profile);
            setName(profile.name)
            setSurname(profile.surname)
            setUsername(profile.username)
            setCell(profile.cellphone)
            setEmail(profile.email)
            setAddress(profile.address)
            setUniversity(profile.university)
            setInstitution(profile.institution)
            setTertiaryLevel(profile.tertiary_level)
            setStudying(profile.studying)
            setGradeTutoring(profile.grade_tutoring)
            setSubjectTutoring(profile.subject_tutoring)
            setMathTopicsComfortable(profile.maths_topics_comfortable)
        }
        else{
            //display error
        }
    }
    function handleMathsComfortable(e){
        const {
        target: { value },
        } = e;

        const topics = typeof value === 'string' ? value.split(',') : value;
        setMathTopicsComfortable(topics);
    }

    async function handleProfileUpdate(e){
        e.preventDefault();
        setShowError(false);
        setLoading(true);
        const update_profile={
            id: '65bd719597022124be8991c4',
            name: fullname,
            surname: surname,
            username: username,
            cellphone: cellphone,
            email: email,
            address: address,
            university: university,
            institution: institution,
            studying: courseStudying,
            tertiary_level: tertiaryLevel,
            grade_tutoring: gradeTutoring,
            subject_tutoring: subjectTutoring,
            maths_topics_comfortable: mathsTopicsComfortable,
        };

        const outcome = await ClientTutor.UpdateTutorById(update_profile);
        setLoading(false);
        if(outcome){
            navigate('/tutor/profile');
        }
        else{
            setShowError(true);
        }
    }
    
    return <>
            <Card  sx={{minWidth: 350}}>
                <Box margin={10}>
                    <Stack spacing={2}>
                        <Typography variant="h3" component="div">
                            Update Profile Details
                        </Typography>
                        <div>
                            <TextField
                            id="tutor-name"
                            label="Name"
                            type="text"
                            color="secondary"
                            value={fullname}
                            onChange={(e)=>{setName(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="tutor-surname"
                            label="Surname"
                            type="text"
                            color="secondary" 
                            value={surname}
                            onChange={(e)=>{setSurname(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="tutor-username"
                            label="Username"
                            type="text"
                            color="secondary" 
                            value={username}
                            onChange={(e)=>{setUsername(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="tutor-cell"
                            label="Cellphone"
                            type="text"
                            color="secondary" 
                            value={cellphone}
                            onChange={(e)=>{setCell(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="tutor-email"
                            label="Email"
                            type="text"
                            color="secondary" 
                            value={email}
                            onChange={(e)=>{setEmail(e.target.value)}}
                            />
                        </div>
                        <div>
                            <InputLabel id="grade-tutoring-label">Grade Tutoring</InputLabel>
                            <Select
                                labelId="grade-tutoring-label"
                                id="grade-tutoring-select"
                                label="Grade Tutoring" 
                                value={gradeTutoring}
                                defaultValue={8}
                                onChange={(e)=>{setGradeTutoring(e.target.value)}}
                            >
                                <MenuItem value={8}>8</MenuItem>
                                <MenuItem value={9}>9</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={11}>11</MenuItem>
                                <MenuItem value={12}>12</MenuItem>
                            </Select>
                        </div>
                        <div>
                            <InputLabel id="subject-tutoring-label">Subject Tutoring</InputLabel>
                            <Select
                                labelId="subject-tutoring-label"
                                id="subject-tutoring-select"
                                label="Subject Tutoring"
                                value={subjectTutoring}
                                defaultValue=""
                                onChange={(e)=>{setSubjectTutoring(e.target.value)}}
                            >
                                <MenuItem value={"Mathematics"}>Maths</MenuItem>
                                <MenuItem value={"Information Technology"}>IT</MenuItem>
                            </Select>
                        </div>
                        
                        <div>
                            <InputLabel id="topics-comfortable-label">Maths Topics Comfortable</InputLabel>
                            <Select
                                labelId="topics-comfortable-label"
                                id="topics-comfortable-select"
                                label="Maths Topics Comfortable" 
                                multiple
                                defaultValue={""}
                                value={mathsTopicsComfortable}
                                onChange={handleMathsComfortable}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {mathsTopics.map((topic) => (
                                    <MenuItem key={topic} value={topic}>
                                        <Checkbox checked={mathsTopicsComfortable.indexOf(topic) > -1} />
                                        <ListItemText primary={topic} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>

                        <div>
                        <InputLabel id="university-label">Studying @</InputLabel>
                            <Select
                                labelId="university-label"
                                id="university-select"
                                label="University" 
                                value={university}
                                defaultValue={""}
                                onChange={(e)=>{setUniversity(e.target.value)}}
                            >
                                <MenuItem value={"UJ APK"}>UJ APK</MenuItem>
                                <MenuItem value={"UJ ABP"}>UJ APB</MenuItem>
                                <MenuItem value={"UJ DFC"}>UJ DFC</MenuItem>
                                <MenuItem value={"Wits University"}>Wits University</MenuItem>
                                <MenuItem value={"UP"}>University of Pretoria</MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                            </Select>
                            <TextField
                            id="tutor-other-uni"
                            label="Other Institution"
                            type="text"
                            color="secondary" 
                            style={{display: 'none'}}
                            onChange={(e)=>{setInstitution(e.target.value)}}
                            />
                        </div>

                        <div>
                            <TextField
                            id="tutor-studying"
                            label="Course Studying"
                            value={courseStudying}
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setStudying(e.target.value)}}
                            />
                        </div>


                        <div>
                            <TextField
                            id="tutor-tertiary-level"
                            label="Tertiary Level (Diploma, Degree, Honors, ETC"
                            type="text"
                            color="secondary" 
                            value={tertiaryLevel}
                            onChange={(e)=>{setTertiaryLevel(e.target.value)}}
                            />
                        </div>

                        <div>
                            <LinearProgress style={{display: loading?'block':'none'}} color="primary"/>
                        </div>
                        <Alert severity="error" className='alert-box' style={{display: showError?'block':'none'}}>
                            <AlertTitle><strong>Error</strong></AlertTitle>
                            Credentials provided do not work try again
                        </Alert>
                        <div className="heel">
                            <Button variant="contained" size="large" onClick={handleProfileUpdate}>
                                Update
                            </Button>
                        </div>
                    </Stack>
                </Box>
            </Card>
        </>
}


function TutorProfileCreation({tutor_username}){
    //create nav function
    const navigate = useNavigate();
    //declare variables
    const [fullname, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [username, setUsername] = useState('');
    const [cellphone, setCell] = useState('');
    const [email, setEmail] = useState('');
    const [accessEmail, setAccessEmail] = useState('');
    const [accessApproved, setAccessApproved] = useState(false);
    const [address, setAddress] = useState('');
    const [university, setUniversity] = useState('');
    const [institution, setInstitution] = useState('');
    const [tertiaryLevel, setTertiaryLevel] = useState('');
    const [courseStudying, setStudying] = useState('');
    const [gradeTutoring, setGradeTutoring] = useState('');
    const [subjectTutoring, setSubjectTutoring] = useState([]);
    const [mathsTopicsComfortable, setMathTopicsComfortable] = useState([]);
    const [password, setPassword] = useState('');

    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [loading, setLoading] = useState(false);
    
    //fetch profile
    useEffect(()=>{
        loadProfile();
    }, []);

    const loadProfile = async()=>{
        console.log('LOADING...');
        setShowError(false);            
        const profile_data = await ClientTutor.GetTutor('email', tutor_username);
        console.log('profile_data: ',  profile_data);
        if(profile_data && profile_data.data){
        }
        else{
            //display error
        }
    }
    
    const verifyProfile = async()=>{
        const profile_data = await ClientTutor.GetTutor('email', accessEmail);

        if(!profile_data || !profile_data.data){
            setErrorMessage('Error verifying email provided. Please check with Mihandzu Admin to get the correct email address used to apply');
            setShowError(true);
            return;
        }

        const profile = profile_data.data;
        setName(profile.name);
        setSurname(profile.surname);
        if(profile.username)
            setUsername(profile.username);
        if(profile.cellphone)
            setCell(profile.cellphone);

        if(profile.email)
            setEmail(profile.email);

        if(profile.address)
            setAddress(profile.address);

        if(profile.university)
            setUniversity(profile.university);

        if(profile.institution)
            setInstitution(profile.institution);

        if(profile.tertiary_level)
            setTertiaryLevel(profile.tertiary_level);

        if(profile.studying)
            setStudying(profile.studying);

        if(profile.grade_tutoring)
            setGradeTutoring(profile.grade_tutoring);

        if(profile.subject_tutoring)
            setSubjectTutoring(profile.subject_tutoring);

        if(profile.maths_topics_comfortable)
            setMathTopicsComfortable(profile.maths_topics_comfortable);

        setAccessApproved(true);
        setShowError(false);          
    }

    function handleMathsComfortable(e){
        const {
        target: { value },
        } = e;

        const topics = typeof value === 'string' ? value.split(',') : value;
        setMathTopicsComfortable(topics);
    }

    async function handleProfileUpdate(e){
        e.preventDefault();
        setShowError(false);

        if(!password || password.length<=0){
            setErrorMessage('Password not provided. Mihandzu account cannot be crated without a password');
            setShowError(true);
            return;
        }
        if(!username || username.length<=0){
            setErrorMessage('Username not provided. Mihandzu account cannot be crated without a username');
            setShowError(true);
            return;
        }

        setLoading(true);
        const new_profile={
            name: fullname,
            surname: surname,
            username: username,
            cellphone: cellphone,
            email: email,
            address: address,
            university: university,
            institution: institution,
            studying: courseStudying,
            tertiary_level: tertiaryLevel,
            grade_tutoring: gradeTutoring,
            subject_tutoring: subjectTutoring,
            maths_topics_comfortable: mathsTopicsComfortable,
            password: password,
        };
        const outcome = await ClientTutor.UpdateTutor({email: email}, new_profile);
        setLoading(false);
        if(outcome){
            navigate('/tutor/auth/login');
        }
        else{
            setShowError(true);
            setErrorMessage('Error happened whiles creating account. Please refresh page and try again');
            
        }
    }
    
    return <>
            <Alert severity="error" className='alert-box' style={{display: showError?'block':'none'}}>
                <AlertTitle><strong>Error</strong></AlertTitle>
                {errorMessage}
            </Alert>
            <Card style={{display: !accessApproved?'block':'none'}}  sx={{minWidth: 350}}>
                <Box margin={10}>
                    <Stack spacing={2}>
                        <Typography variant="h3" component="div">
                            Create Tutor Profile
                        </Typography>
                        <Typography variant="h6" component="div">
                            Your profile was approved by Mihandzu's Admin. Please enter the email you used to apply to complete the process:
                        </Typography>
                        <div>
                            <TextField
                            id="tutor-access-email"
                            label="Email Address"
                            type="text"
                            color="secondary"
                            value={accessEmail}
                            onChange={(e)=>{setAccessEmail(e.target.value)}}
                            />
                        </div>

                        <div style={{display: loading?'block':'none'}}>
                            <LinearProgress color="primary"/>
                        </div>
                        <div className="heel">
                            <Button variant="contained" size="large" onClick={verifyProfile}>
                                Verify
                            </Button>
                        </div>
                    </Stack>
                </Box>
            </Card>
            <Card style={{display: accessApproved?'block':'none'}} sx={{minWidth: 350}}>
                <Box margin={10}>
                    <Stack spacing={2}>
                        <Typography variant="h3" component="div">
                            Create Tutor Profile
                        </Typography>
                        <div>
                            <TextField
                            id="tutor-name"
                            label="Name"
                            type="text"
                            color="secondary"
                            value={fullname}
                            onChange={(e)=>{setName(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="tutor-surname"
                            label="Surname"
                            type="text"
                            color="secondary" 
                            value={surname}
                            onChange={(e)=>{setSurname(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="tutor-username"
                            label="Username"
                            type="text"
                            color="secondary" 
                            value={username}
                            required="true"
                            onChange={(e)=>{setUsername(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="tutor-cell"
                            label="Cellphone"
                            type="text"
                            color="secondary" 
                            value={cellphone}
                            onChange={(e)=>{setCell(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="tutor-email"
                            label="Email"
                            type="text"
                            color="secondary" 
                            value={email}
                            onChange={(e)=>{setEmail(e.target.value)}}
                            />
                        </div>
                        <div>
                            <InputLabel id="grade-tutoring-label">Grade Tutoring</InputLabel>
                            <Select
                                labelId="grade-tutoring-label"
                                id="grade-tutoring-select"
                                label="Grade Tutoring" 
                                value={gradeTutoring}
                                defaultValue={8}
                                onChange={(e)=>{setGradeTutoring(e.target.value)}}
                            >
                                <MenuItem value={8}>8</MenuItem>
                                <MenuItem value={9}>9</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={11}>11</MenuItem>
                                <MenuItem value={12}>12</MenuItem>
                            </Select>
                        </div>
                        <div>
                            <InputLabel id="subject-tutoring-label">Subject Tutoring</InputLabel>
                            <Select
                                labelId="subject-tutoring-label"
                                id="subject-tutoring-select"
                                label="Subject Tutoring"
                                value={subjectTutoring}
                                defaultValue=""
                                onChange={(e)=>{setSubjectTutoring(e.target.value)}}
                            >
                                <MenuItem value={"Mathematics"}>Maths</MenuItem>
                                <MenuItem value={"Information Technology"}>IT</MenuItem>
                            </Select>
                        </div>
                        
                        <div>
                            <InputLabel id="topics-comfortable-label">Maths Topics Comfortable</InputLabel>
                            <Select
                                labelId="topics-comfortable-label"
                                id="topics-comfortable-select"
                                label="Maths Topics Comfortable" 
                                multiple
                                value={mathsTopicsComfortable}
                                onChange={handleMathsComfortable}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {mathsTopics.map((topic) => (
                                    <MenuItem key={topic} value={topic}>
                                        <Checkbox checked={mathsTopicsComfortable.indexOf(topic) > -1} />
                                        <ListItemText primary={topic} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>

                        <div>
                        <InputLabel id="university-label">Studying @</InputLabel>
                            <Select
                                labelId="university-label"
                                id="university-select"
                                label="University" 
                                value={university}
                                defaultValue={""}
                                onChange={(e)=>{setUniversity(e.target.value)}}
                            >
                                <MenuItem value={"UJ APK"}>UJ APK</MenuItem>
                                <MenuItem value={"UJ ABP"}>UJ APB</MenuItem>
                                <MenuItem value={"UJ DFC"}>UJ DFC</MenuItem>
                                <MenuItem value={"Wits University"}>Wits University</MenuItem>
                                <MenuItem value={"UP"}>University of Pretoria</MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                            </Select>
                            <TextField
                            id="tutor-other-uni"
                            label="Other Institution"
                            type="text"
                            color="secondary" 
                            style={{display: 'none'}}
                            onChange={(e)=>{setInstitution(e.target.value)}}
                            />
                        </div>

                        <div>
                            <TextField
                            id="tutor-studying"
                            label="Course Studying"
                            value={courseStudying}
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setStudying(e.target.value)}}
                            />
                        </div>


                        <div>
                            <TextField
                            id="tutor-tertiary-level"
                            label="Tertiary Level (Diploma, Degree, Honors, ETC"
                            type="text"
                            color="secondary" 
                            value={tertiaryLevel}
                            onChange={(e)=>{setTertiaryLevel(e.target.value)}}
                            />
                        </div>


                        <div>
                            <TextField
                            id="tutor-pass"
                            label="Password"
                            type="password"
                            color="secondary" 
                            value={password}
                            onChange={(e)=>{setPassword(e.target.value)}}
                            />
                        </div>

                        <div>
                            <LinearProgress style={{display: loading?'block':'none'}} color="primary"/>
                        </div>
                        <div className="heel">
                            <Button variant="contained" size="large" onClick={handleProfileUpdate}>
                                Update
                            </Button>
                        </div>

                        <Alert severity="error" className='alert-box' style={{display: showError?'block':'none'}}>
                            <AlertTitle><strong>Error</strong></AlertTitle>
                            {errorMessage}
                        </Alert>
                    </Stack>
                </Box>
            </Card>
        </>
}


function TutorProfile() {
    const params = useParams();
    const action = params.action;
    const tutor = params.tutor;
    console.log('Tutor: ', tutor);
    if(action==="update"){
        render = <ProfileDetails/>
    } 
    else if(tutor==="new_tutor"){
        render = <TutorProfileCreation tutor_username={tutor} />
    }
    else{
        render = <UpdateDetails/>
    }
    return (
        <div>
            {alert}
            <Box 
                component="form"
                sx={{
                '& .MuiTextField-root': { m: 2, width: '35ch' },
                }}
            noValidate
            autoComplete="off"
            >
                {render}
                <a
                className="App-link"
                href="https://reactjs.org"
                target="_blank"
                rel="noopener noreferrer"
                >
                </a>
            </Box>
        </div>
    )
}

export default TutorProfile;