import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Alert, AlertTitle, CardHeader, CardContent, CardActions, Avatar, LinearProgress } from "@mui/material";
import {List, ListItem, ListItemAvatar} from '@mui/material';
import { AddchartRounded, PersonAddAlt1Rounded, ReplayRounded, EditNoteRounded} from '@mui/icons-material';
import { green } from '@mui/material/colors';

import {useEffect, useState} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import tutor from '../../client/tutor';

function TutorHome(){
    const navigate = useNavigate();
    //load ui variables
    const [session_count, setSessionCount] = useState(0);
    const [tutor_name, setTutorName] = useState('');
    const [subject_tutoring, setSubjectTutoring] = useState('');
    const [grade_tutoring, setGradeTutoring] = useState(8);
    const [recent_class, setRecentClass] = useState({});
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    //load stored tutor details
    const tutor_id = sessionStorage.getItem("user_id");
    const tutor_token = sessionStorage.getItem("user_token");
    useEffect(()=>{
        loadDashboard()
    }, []);

    const loadDashboard = async()=>{
        //load stoage data
        setTutorName(sessionStorage.getItem("user_fullname"));

        //load server data
        setLoading(true);
        const dash_data = await tutor.GetTutorDash(tutor_id, tutor_token);
        console.log('DASH_D: ', dash_data)
        if(dash_data.data){
            setSessionCount(dash_data.data.total_sessions);
            setTutorName(dash_data.data.tutor.name + " " + dash_data.data.tutor.surname);
            setSubjectTutoring(dash_data.data.tutor.subject_tutoring);
            setGradeTutoring(dash_data.data.tutor.grade_tutoring);
            
            let class_details = {};
            if(dash_data.data.recent_class.class){
                class_details = {
                    class_name: dash_data.data.recent_class.class.name ||'Unknown Class',
                    topics_covered: dash_data.data.recent_class.class_topics ||'',
                    num_learners: dash_data.data.recent_class.learners.length
                }

                if(dash_data.data.recent_class.tutors.length>0){
                    let tutor_names="";
                    for(let i=0; i<dash_data.data.recent_class.tutors.length; i+=1){
                        const tutor = dash_data.data.recent_class.tutors[i];
                        tutor_names += tutor.name + " " + tutor.surname + ", ";
                    }
                    class_details.tutors_present = tutor_names;
                }
            }
            setRecentClass(class_details);
        }
        setLoading(false);
    }

    return (
        <div>
        <Alert severity="error" className='alert-box' style={{display: showError?'block':'none'}}>
            <AlertTitle>Error</AlertTitle>
            Credentials provided do not work — <strong>try again!</strong>
        </Alert>
        <Typography variant="h3" component="div">
            Hello {tutor_name}
        </Typography>

        <Box 
            sx={{ display: 'flex', flexDirection: 'column' }}>

            <div style={{display: loading?'block':'none'}}>
                
                <LinearProgress  color="primary"/>

                <Typography variant="h6" component="div">
                    Loading Dashboard...
                </Typography>
            </div>
            <div 
                style={{display: !loading?'block':'none'}}>
            <Grid2 
                container 
                spacing={2}>
                <Grid2 xs={12} md={6}>
                    <Card  sx={{maxWidth: 350}}
                        >
                        <CardHeader
                            title={subject_tutoring}
                        />
                        <CardContent style={{ justifyContent: "center", display: "flex" }}>
                            Grade
                            <Avatar sx={{ bgcolor: green[600] }} aria-label="recipe">
                            {grade_tutoring}
                            </Avatar>
                        </CardContent>

                        <CardContent style={{ justifyContent: "center", display: "flex" }}>
                            Total Sessions
                            <Avatar sx={{ bgcolor: green[600] }} aria-label="recipe">
                            {session_count}
                            </Avatar>
                        </CardContent>
                    </Card>
                </Grid2>
                <Grid2 xs={12} md={6} style={{display: recent_class.class_name?'block':'none'}} >
                    <Card  sx={{maxWidth: 350}}>
                        <CardHeader
                            title="Most Recent Class"
                        />
                        <CardContent style={{ justifyContent: "center", display: "flex" }}>
                            <Avatar sx={{ bgcolor: green[900] }} aria-label="recipe">
                            {recent_class.date}
                            </Avatar>
                        </CardContent>
                        <CardContent style={{ justifyContent: "center", display: "flex" }}>
                            <div>
                                Class Name: <span>{recent_class.class_name}</span>
                            </div>
                        </CardContent>
                        <CardContent style={{ justifyContent: "center", display: "flex" }}>
                            <div>
                                Topics Covered: <span>{recent_class.topics_covered}</span>
                            </div>
                        </CardContent>

                        <CardContent style={{ justifyContent: "center", display: "flex" }}>
                            <div>
                                Other Tutors Present: <span>{recent_class.tutors_present}</span>
                            </div>
                        </CardContent>
                        <CardContent style={{ justifyContent: "center", display: "flex" }}>
                            <div>
                                Number of Learners: <span>{recent_class.num_learners}</span>
                            </div>
                        </CardContent>
                    </Card>
                </Grid2>
            </Grid2>
        </div>
        <br/>
        <div 
            style={{display: !loading?'block':'none'}}>
            <Card elevation={3}>
                <CardContent style={{ justifyContent: "center", display: "flex" }}>
                   <List>
                        <ListItem>
                            <Typography> 
                                <Link to='/tutor/attendance'>Take Attendance</Link>
                            </Typography>
                            <ListItemAvatar>
                                <Avatar>
                                <Link to='/tutor/attendance'><AddchartRounded /></Link>
                                </Avatar>
                            </ListItemAvatar>
                        </ListItem>
                        <hr/>
                        <ListItem>

                            <Typography>
                                <Link to='/tutor/classes'> View Previous Classes</Link>
                            </Typography>
                            <ListItemAvatar>
                                <Avatar>
                                    <Link to='/tutor/classes'><ReplayRounded /></Link>
                                </Avatar>
                            </ListItemAvatar>
                        </ListItem>
                        <hr/>
                        <ListItem>

                            <Typography>
                                <Link to='/tutor/learner/new'> Add Learners</Link>
                            </Typography>
                            <ListItemAvatar>
                                <Avatar>
                                    <Link to='/tutor/learner/new'><PersonAddAlt1Rounded /></Link>
                                </Avatar>
                            </ListItemAvatar>
                        </ListItem>
                        <hr/>
                        <ListItem>

                            <Typography>
                                <Link to='/tutor/profile'>Edit My Profile</Link>
                            </Typography>
                            <ListItemAvatar>
                                <Avatar>
                                <EditNoteRounded />
                                </Avatar>
                            </ListItemAvatar>
                        </ListItem>
                   </List>
                </CardContent>
                </Card>

                </div>
            <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
            >
            </a>
        </Box>
        </div>
    )
}

export default TutorHome