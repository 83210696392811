 import Config from '../utils/config';
 import axios from 'axios';
 const Client = {};

 //create axios instance
 const instance = axios.create({
  baseURL: 'http://localhost:7000/',
  timeout: 15000,
  // headers: {'Authorization': auth.GetToken}
});

 /*
 *
 *LEARNERS
 *
 */
 
 Client.CreateLearner = async (new_learner)=>{
     try{
        const bearer_token = "bearer " + sessionStorage.getItem("user_token");
         const res = await instance.post(Config.network.LEARNER_CREATION,
             new_learner,
             {
                 headers: {
                     'Content-Type': 'application/json',
                     'Authorization': bearer_token
                 }
             })
         if(res.status === 200){
             return true;
         }
         else{
             return false;
         }
     }
     catch(error){
         console.log('Learner Creation Error: ', error);
         return false;
     }
 }

 Client.GetLearners = async (filter)=>{
    const result={};
    try{
        console.log('LEARNER FILTER: ', filter)
        const bearer_token = "bearer " + sessionStorage.getItem("user_token");
        const res = await instance.post(Config.network.LEARNERS,
            filter,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': bearer_token
                }
            })
        if(res.status === 200){
            result.data = res.data.data;
        }
        else{
            result.data =  {error: res.status+' error occured whiles fetching class'};
        }
    }
    catch(error){
        console.log('Getting Learner Error: ', error);
        result.error = error;
    }
    return result;
}
 
 /*
 *
 *CLASS
 *
 */
 
 Client.CreateClass = async (new_class)=>{
  try{
    const bearer_token = "bearer " + sessionStorage.getItem("user_token");
        const res = await instance.post(Config.network.CLASS,
            new_class,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': bearer_token
                }
            })
        if(res.status === 200){
            return res.data;
        }
        else{
            return {error: res.status+' error occured whiles fetching tutor'};
        }
  }
  catch(error){
      console.log('Class Creation Error: ', error);
      return false;
  }
}
Client.GetClass = async ()=>{
    const result = {};
    try{
        const bearer_token = "bearer " + sessionStorage.getItem("user_token");
        const res = await instance.get(Config.network.CLASS,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': bearer_token
                }
            })
        if(res.status === 200){
            result.data = res.data;
        }
        else{
            result.data =  {error: res.status+' error occured whiles fetching class'};
        }
    }
    catch(error){
        console.log('Error Fetching Class: ', error);
        result.error = error;
    }
    return result;
  }


 /*
 *
 *ATTENDANCE
 *
 */
 
 Client.CreateAttendance = async (new_att)=>{
    const result={};
    try{
        const bearer_token = "bearer " + sessionStorage.getItem("user_token");
          const res = await instance.post(Config.network.ATTENDANCE_NEW_CAPTURE,
              new_att,
              {
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': bearer_token
                  }
              })
          if(res.status === 200){
              result.data = res.data;
          }
          else{
              result.error= res.status+' error occured whiles fetching tutor';
          }
    }
    catch(error){
        console.log('Attendance Creation Error: ', error);
        result.error=error;
    }
    return result;
  }
 
  
  Client.UpdateAttendance = async (attendance_id, update_att)=>{
    try{
        const bearer_token = "bearer " + sessionStorage.getItem("user_token");
        const res = await axios.put(Config.network.ATTENDANCE_UPDATE+attendance_id,
            update_att
            ,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': bearer_token
                }
            })
        if(res.status === 200){
            return true;
        }
        else{
            return false;
        }
    }
    catch(error){
        console.log('Attendance Update Error: ', error);
        return false;
    }
}
 export default Client