import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Alert, AlertTitle, CardHeader, CardContent, Avatar, LinearProgress, Stack } from "@mui/material";
import { green } from '@mui/material/colors';
import UnpublishedIcon from '@mui/icons-material/Unpublished';

import {useEffect, useState} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import tutor from '../../client/tutor';

function TutorClasses(){
    const navigate = useNavigate();

    const [tutor_name, setTutorName] = useState("");
    const [previous_classes, setPreviousClasses] = useState([]);
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(()=>{
        loadTutorClasses()
    }, []);

    const loadTutorClasses = async()=>{
        setLoading(true);

        //load stored tutor details
        const tutor_id = sessionStorage.getItem("user_id");
        setTutorName(sessionStorage.getItem("user_fullname"));
        //load tutor prev classes
        let classes = [];
        let dash_data = await tutor.GetTutorAttendance(tutor_id);
        if(dash_data && dash_data.data){
            dash_data = dash_data.data;
            for(let i=0; i<dash_data.data.length; i+=1){
                const class_data = dash_data.data[i];
                let tutor_names = "";
                if(class_data.tutors.length>0){
                    for(let i=0; i<class_data.tutors.length; i+=1){
                        const tutor = class_data.tutors[i].name + " " + class_data.tutors[i].surname;
                        if(tutor_name != tutor){
                            tutor_names += tutor + ", ";
                        }
                    }
                }
                class_data.tutors_present = tutor_names;
                classes.push(class_data);
            }
        }
        setPreviousClasses(classes);
        setLoading(false);
    }

    return (
        <div>
        <Alert severity="error" className='alert-box' style={{display: showError?'block':'none'}}>
            <AlertTitle>Error</AlertTitle>
            Credentials provided do not work — <strong>try again!</strong>
        </Alert>
        <Typography style={{display: previous_classes.length>0?'block':'none'}} variant="h3" component="div">
            {tutor_name} Previous Classes
        </Typography>

        <Box 
            sx={{ display: 'flex', flexDirection: 'column' }}>

            <div style={{display: loading?'block':'none'}}>
                
                <LinearProgress  color="primary"/>

                <Typography variant="h6" component="div">
                    Loading Previous Classes...
                </Typography>
            </div>
            <Card style={{display: previous_classes.length==0?'block':'none'}} sx={{minWidth: 350}}>
                <Box margin={10}>
                    <Stack spacing={2}>
                        <Typography variant="h3" component="div">
                            No Classes
                        </Typography>
                        <div>
                                <UnpublishedIcon sx={{ fontSize: 175 }} color="#2A743C"/>
                        </div>

                        <Typography variant="h6" component="div">
                            You are now ready to capture attendance. See you Saturday!
                        </Typography>
                    </Stack>
                </Box>
            </Card>
            <div 
                style={{display: !loading?'block':'none'}}>
            <Grid2 
                container 
                spacing={2}>

                    {previous_classes.map((pre_class)=>(<>
                         <Grid2 xs={6} md={4}>
                            <Card  sx={{maxWidth: 350}}
                                >
                                <CardHeader
                                    title={pre_class.class.name}
                                    subheader={pre_class.class.subject}
                                />
                                <CardContent style={{ justifyContent: "center", display: "flex" }}>
                                    Grade
                                    <Avatar sx={{ bgcolor: green[600] }} aria-label="recipe">
                                    {pre_class.class.grade}
                                    </Avatar>
                                </CardContent>
        
                                <CardContent style={{ justifyContent: "center", display: "flex" }}>
                                    <div>
                                        Topics Covered: <span>{pre_class.class_topics.toString()}</span>
                                    </div>
                                </CardContent>
        
                                <CardContent style={{ justifyContent: "center", display: "flex" }}>
                                    <div>
                                        Other Tutors Present: <span>{pre_class.tutors_present}</span>
                                    </div>
                                </CardContent>
                                <CardContent style={{ justifyContent: "center", display: "flex" }}>
                                    <div>
                                        Number of Learners: <span>{pre_class.learners.length}</span>
                                    </div>
                                </CardContent>
                                {/* TODO: List all learners present in the class*/}
                            </Card>
                        </Grid2>
                </>))}
            </Grid2>
            <Grid2>
                <Link to='/tutor/home'>Back 2 Dashboard</Link>
            </Grid2>
        </div>
        </Box>
        </div>
    )
}

export default TutorClasses;