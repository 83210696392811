import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';
// import routes from './utils/config'
// import TutorHome from './components/tutor/home';

const root = ReactDOM.createRoot(document.getElementById('root'));
// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#50d8af',
//     },
//     secondary: {
//       main: '#20634F',
//     },
//   },
// });
// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: App,
//   },
//   {
//     path: "/tutor/home",
//     element: TutorHome,
//   }
// ]);

root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
