import Config from '../utils/config';
 import axios from 'axios';
 const Client = {};
 
 Client.UpdateTutorById = async (update_tutor)=>{
     try{
        const bearer_token = "bearer " + sessionStorage.getItem("user_token");
         const res = await axios.put(Config.network.TUTOR_UPDATE+update_tutor.id,
            update_tutor
             ,
             {
                 headers: {
                     'Content-Type': 'application/json',
                     'Authorization': bearer_token
                 }
             })
         if(res.status === 200){
             return true;
         }
         else{
             return false;
         }
     }
     catch(error){
         console.log('Tutor Update Error: ', error);
         return false;
     }
 }
  
 Client.UpdateTutor = async (filter, update_tutor)=>{
    try{
        const bearer_token = "bearer " + sessionStorage.getItem("user_token");
        const res = await axios.put(Config.network.TUTOR_UPDATE,
           {
            filter: filter,
            updated_tutor: update_tutor
           }
            ,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': bearer_token
                }
            })
        if(res.status === 200){
            return true;
        }
        else{
            return false;
        }
    }
    catch(error){
        console.log('Tutor Update Error: ', error);
        return false;
    }
}
  
Client.GetTutorByID = async (tutor_id)=>{
    try{
        const bearer_token = "bearer " + sessionStorage.getItem("user_token");
        const res = await axios.get(Config.network.TUTOR_DETAILS+tutor_id,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': bearer_token
                }
            })
        if(res.status === 200){
            return res.data;
        }
        else{
            return {error: res.status+' error occured whiles fetching tutor'};
        }
    }
    catch(error){
        console.log('Tutor Update Error: ', error);
        return false;
    }
}
Client.GetTutor = async (key, value)=>{
    const result = {};
    try{
        const bearer_token = "bearer " + sessionStorage.getItem("user_token");
        const res = await axios.get(Config.network.TUTOR_DETAILS+"?column="+key+"&value="+value,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': bearer_token
                }
            })
            console.log('RES: ', res);
        if(res.status === 200){
            result.data = res.data.data;
        }
        else{
            result.error = {error: res.status+' error occured whiles fetching tutor'};
        }
    }
    catch(error){
        console.log('Fetching Tutor Error: ', error);
        result.error = error;
    }
    return result;
}
Client.GetTutors = async (filter)=>{
    const result = {};
    try{
        const bearer_token = "bearer " + sessionStorage.getItem("user_token");
        const res = await axios.post(Config.network.TUTORS,
            filter,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': bearer_token
                }
            })
            console.log('RES: ', res);
        if(res.status === 200){
            result.data = res.data.data;
        }
        else{
            result.error = {error: res.status+' error occured whiles fetching tutor'};
        }
    }
    catch(error){
        console.log('Fetching Tutors Error: ', error);
        result.error = error;
    }
    return result;
}

Client.GetTutorDash = async (tutor_id, token)=>{
    const result = {};
    try{
        const bearer_token = "bearer " + sessionStorage.getItem("user_token");
        const res = await axios.get(Config.network.TUTOR_DASH+tutor_id,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': [bearer_token]
                }
            })
            console.log('Data: ',res)
        if(res.status === 200){
            result.data = res.data;
        }
        else{
            result.error = {error: res.status+' error occured whiles fetching tutor'};
        }
    }
    catch(error){
        console.log('Getting Tutor Dash Error: ', error);
        result.error = error;
    }
    return result;
}

Client.GetTutorAttendance = async (tutor_id)=>{
    const result = {};
    try{
        const bearer_token = "bearer " + sessionStorage.getItem("user_token");
        const res = await axios.get(Config.network.TUTOR_DETAILS+tutor_id+"/attendance",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': [bearer_token],
                }
            })
        if(res.status === 200){
            result.data = res.data;
        }
        else{
            result.error = {error: res.status+' error occured whiles fetching tutor'};
        }
    }
    catch(error){
        console.log('Tutor Class Attendance Fetch Error: ', error);
        result.error = error;
    }
    return result;
}

Client.GetTutorApplications = async ()=>{
    const result = {};
    try{
        const bearer_token = "bearer " + sessionStorage.getItem("user_token");
        const res = await axios.get(Config.network.ADMIN_TUTOR_APPLICATIONS,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': [bearer_token],
                }
            })
        if(res.status === 200){
            result.data = res.data;
        }
        else{
            result.error = {error: res.status+' error occured whiles fetching tutor'};
        }
    }
    catch(error){
        console.log('Tutor Application Fetch Error: ', error);
        result.error = error;
    }
    return result;
}
 export default Client;