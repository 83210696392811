import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import { TextField, Alert, AlertTitle, Select, MenuItem, InputLabel, Checkbox, ListItemText} from "@mui/material";

import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Client from '../../client/auth'; 

const mathsTopics = [
    'Whole Numbers',
    'Common Fractions',
    'Integers',
    'Exponents',
    'Numeric & Geometric Patterns',
    'Algeraic Equations',
    'Algeraic Expressions',
    'Graphs',
    'Geometry of Straight Lines',
    'Geometry of 2D Shapes',
    'Transformation Geometry',
    'Functions & Relationships',
    'Area & Permimeter of 2D Shapes',
    'Surface and Volume',
    'Theorem of Pythagoras',
    'Revision',
  ];

function AddTutor(){
    //create nav function
    const navigate = useNavigate();
    //declare variables
    const [fullname, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [username, setUsername] = useState('');
    const [cellphone, setCell] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [university, setUniversity] = useState('');
    const [institution, setInstitution] = useState('');
    const [tertiaryLevel, setTertiaryLevel] = useState('');
    const [courseStudying, setStudying] = useState('');
    const [gradeTutoring, setGradeTutoring] = useState('');
    const [subjectTutoring, setSubjectTutoring] = useState('');
    const [mathsTopicsComfortable, setMathTopicsComfortable] = useState([]);
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);

    function handleMathsComfortable(e){
        const {
        target: { value },
        } = e;

        const topics = typeof value === 'string' ? value.split(',') : value;
        setMathTopicsComfortable(topics);
    }

    async function handleTutorRegistration(e){
        e.preventDefault();
        setShowError(false);
        setLoading(true);
        const new_tutor={
            name: fullname,
            surname: surname,
            username: username,
            cellphone: cellphone,
            email: email,
            address: address,
            university: university,
            institution: institution,
            studying: courseStudying,
            tertiary_level: tertiaryLevel,
            gradeTutoring: gradeTutoring,
            subject_tutoring: subjectTutoring,
            maths_topics_comfortable: mathsTopicsComfortable,
        };

        const outcome = await Client.CreateTutor(new_tutor);
        setLoading(false);
        if(outcome){
            navigate('/admin/home');
        }
        else{
            setShowError(true);
        }
    }
    
    return <>
            <Card>
                <Box margin={10}>
                    <Stack spacing={2}>
                        <Typography variant="h3" component="div">
                            Add New Tutor
                        </Typography>
                        <div>
                            <TextField
                            id="tutor-name"
                            label="Fullname"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setName(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="tutor-surname"
                            label="Surname"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setSurname(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="tutor-username"
                            label="Username"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setUsername(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="tutor-cell"
                            label="Cellphone"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setCell(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="tutor-email"
                            label="Email"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setEmail(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="tutor-address"
                            label="Address"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setAddress(e.target.value)}}
                            />
                        </div>
                        <div>
                            <InputLabel id="grade-tutoring-label">Grade Tutoring</InputLabel>
                            <Select
                                labelId="grade-tutoring-label"
                                id="grade-tutoring-select"
                                label="Grade Tutoring" 
                                value={gradeTutoring}
                                defaultValue={0}
                                onChange={(e)=>{setGradeTutoring(e.target.value)}}
                            >
                                <MenuItem value={8}>8</MenuItem>
                                <MenuItem value={9}>9</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={11}>11</MenuItem>
                                <MenuItem value={12}>12</MenuItem>
                            </Select>
                        </div>
                        <div>
                            <InputLabel id="subject-tutoring-label">Subject Tutoring</InputLabel>
                            <Select
                                labelId="subject-tutoring-label"
                                id="subject-tutoring-select"
                                label="Subject Tutoring" 
                                defaultValue=""
                                onChange={(e)=>{setSubjectTutoring(e.target.value)}}
                            >
                                <MenuItem value={"Mathematics"}>Maths</MenuItem>
                                <MenuItem value={"Information Technology"}>IT</MenuItem>
                            </Select>
                        </div>
                        
                        <div>
                            <InputLabel id="topics-comfortable-label">Maths Topics Comfortable</InputLabel>
                            <Select
                                labelId="topics-comfortable-label"
                                id="topics-comfortable-select"
                                label="Maths Topics Comfortable" 
                                multiple
                                defaultValue={""}
                                value={mathsTopicsComfortable}
                                onChange={handleMathsComfortable}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {mathsTopics.map((topic) => (
                                    <MenuItem key={topic} value={topic}>
                                        <Checkbox checked={mathsTopicsComfortable.indexOf(topic) > -1} />
                                        <ListItemText primary={topic} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>

                        <div>
                        <InputLabel id="university-label">Studying @</InputLabel>
                            <Select
                                labelId="university-label"
                                id="university-select"
                                label="University" 
                                defaultValue={""}
                                onChange={(e)=>{setUniversity(e.target.value)}}
                            >
                                <MenuItem value={"UJ APK"}>UJ APK</MenuItem>
                                <MenuItem value={"UJ ABP"}>UJ APB</MenuItem>
                                <MenuItem value={"UJ DFC"}>UJ DFC</MenuItem>
                                <MenuItem value={"Wits University"}>Wits University</MenuItem>
                                <MenuItem value={"UP"}>University of Pretoria</MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                            </Select>
                            <TextField
                            id="tutor-other-uni"
                            label="Other Institution"
                            type="text"
                            color="secondary" 
                            style={{display: 'none'}}
                            onChange={(e)=>{setInstitution(e.target.value)}}
                            />
                        </div>

                        <div>
                            <TextField
                            id="tutor-studying"
                            label="Course Studying"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setStudying(e.target.value)}}
                            />
                        </div>


                        <div>
                            <TextField
                            id="tutor-tertiary-level"
                            label="Tertiary Level (Diploma, Degree, Honors, ETC"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setTertiaryLevel(e.target.value)}}
                            />
                        </div>

                        <div>
                            <LinearProgress style={{display: loading?'block':'none'}} color="primary"/>
                        </div>
                        <Alert severity="error" className='alert-box' style={{display: showError?'block':'none'}}>
                            <AlertTitle><strong>Error</strong></AlertTitle>
                            Credentials provided do not work try again
                        </Alert>
                        <div className="heel">
                            <Button variant="contained" size="large" onClick={handleTutorRegistration}>
                                Submit
                            </Button>
                        </div>
                    </Stack>
                </Box>
            </Card>
            
        </>
}

export default AddTutor;