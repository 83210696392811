import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import { Alert, AlertTitle,TextField} from "@mui/material";

import { useState } from 'react';

import AdminClient from '../../client/admin'; 

function EagleLogin(props){

    //variables from parent component
    const is_admin = props.is_admin;
    const set_admin = props.set_admin;
    const set_active_step = props.set_active_step;

    //data related variables
    const [loginCode, setLoginCode] = useState('');

    //ui variables
    const [showError, setShowError] = useState(false);
    const [loadingScreen, setLoadingScreen] = useState(false);

    const authenticateDetails = async()=>{
        setLoadingScreen(true);
        if(loginCode==='open-the-fruit-eagle-mode'){
            set_admin(true);
            set_active_step('add_admin');
        }
        else{
            setShowError(true);
        }
        setLoadingScreen(false);
    }

    return <div style={{display: !is_admin?'block':'none'}}>
            <div>
                
                <Typography variant="h2" component="div">
                    Eagle Mode Login
                </Typography>
            </div>
            <div>
                <TextField
                    id="login-code"
                    label="Eagle Login Code"
                    type="text"
                    color="secondary"
                    helperText="Super admin login credentials required"
                    onChange={(e)=>{setLoginCode(e.target.value)}}
                    />
            </div>
            <div>
                <LinearProgress style={{display: loadingScreen?'block':'none'}} color="primary"/>
            </div>
            <Alert severity="error" className='alert-box' style={{display: showError?'block':'none'}}>
                <AlertTitle><strong>Error</strong></AlertTitle>
                Error loging in to super admin. Please refresh page and try again
            </Alert>
            <div className="heel" style={{display: !loadingScreen?'block':'none'}} >
                <Button variant="contained" size="large" onClick={authenticateDetails}>
                    Login
                </Button>
            </div>
    </div>
}

function AddAdmin(props){
    //variables from parent component
    const is_admin = props.is_admin;
    
    //data related variables
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    //ui variables
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [loading, setLoading] = useState(false);


    async function createAdmin(e){
        e.preventDefault();
        setShowError(false);
        setLoading(true);
        const new_admin={
            name: name,
            surname: surname,
            username: username,
            password: password,
        };

        const outcome = await AdminClient.CreateAdmin(new_admin);
        setLoading(false);
        if(outcome){
            setShowSuccess(true);
        }
        else{
            setShowError(true);
        }
    }
    
    return <div style={{display: is_admin?'block':'none'}}>
            <Card>
                <Box margin={10}>
                    <Stack spacing={2}>
                        <Typography variant="h3" component="div">
                            Add New Admin
                        </Typography>

                        <Alert severity="success" className='alert-box' style={{display: showSuccess?'block':'none'}}>
                            <AlertTitle><strong>Done</strong></AlertTitle>
                            Admin Created Successfully
                        </Alert>
                        <div>
                            <TextField
                            id="admin-name"
                            label="Fullname"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setName(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="admin-surname"
                            label="Surname"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setSurname(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="admin-username"
                            label="Username"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setUsername(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="admin-pass"
                            label="Password"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setPassword(e.target.value)}}
                            />
                        </div>
                        <div>
                            <LinearProgress style={{display: loading?'block':'none'}} color="primary"/>
                        </div>
                        <Alert severity="error" className='alert-box' style={{display: showError?'block':'none'}}>
                            <AlertTitle><strong>Error</strong></AlertTitle>
                            Credentials provided do not work try again
                        </Alert>
                        <div className="heel">
                            <Button variant="contained" size="large" onClick={createAdmin}>
                                Submit
                            </Button>
                        </div>
                    </Stack>
                </Box>
            </Card>
        </div>
}


function EagleHome(){
    const [active_step, setActiveStep] = useState('login');
    const [is_admin, setAdmin] = useState(false);

    return <>
            <Card>
                <Box margin={10}>
                    <Stack spacing={2}>
                        <EagleLogin 
                            is_active={active_step==="step_one"} 
                            set_admin={setAdmin}
                            set_active_step={setActiveStep} />

                        <AddAdmin 
                            is_admin={is_admin} 
                            />
                    </Stack>
                </Box>
            </Card>
            
        </>
}

function LoadEagle() {
    const render = <EagleHome/>
    
    return (
        <div>
            {alert}
            <Box 
                component="form"
                sx={{
                '& .MuiTextField-root': { m: 2, width: '35ch' },
                }}
            noValidate
            autoComplete="off"
            >
                {render}
            </Box>
        </div>
    )
}

export default LoadEagle;