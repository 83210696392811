const Config = {};

Config.API = 'https://96r1acrobg.execute-api.us-east-2.amazonaws.com/Prod';//'http://localhost:6001

Config.network = {
    TUTOR_LOGIN: Config.API + '/auth/tutor/login',
    TUTOR_REGISTER: Config.API + '/auth/tutor/register',
    TUTOR_APPLY: Config.API + '/auth/tutor/apply',
    TUTOR_UPDATE: Config.API + '/tutor/',
    TUTOR_DETAILS: Config.API + '/tutor/',
    TUTOR_DASH: Config.API + '/tutor/dashboard/',
    TUTORS: Config.API + '/tutors/',

    ADMIN_LOGIN: Config.API + '/auth/admin/login',
    ADMIN_DASH: Config.API + '/admin/dashboard',
    ADMIN_TUTOR_APPLICATIONS: Config.API + '/admin/tutor/applications/',
    ADMIN_APPROVE_TUTOR: Config.API + '/admin/tutor/application/approve/',
    
    EAGLE_ADD_ADMIN: Config.API + '/admin/eagle/new/admin/',

    LEARNER_CREATION : Config.API + '/learner/',
    LEARNERS : Config.API + '/learners/',

    ATTENDANCE_NEW_CAPTURE: Config.API + '/tutor/attendance/new',
    ATTENDANCE_UPDATE: Config.API + '/tutor/attendance/',
    CLASS: Config.API + '/class',


    VERIFY_TOKEN: Config.API + '/verify/token',
};

Config.security = {
    EAGLE_ACCESS_CODE: 'EW6FO9qCvr1omeIrylWtPOmMbwK43z',
};
export default Config;