import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import { TextField, Alert, AlertTitle, Select, MenuItem, InputLabel} from "@mui/material";

import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Client from '../../client/learners'; 


function AddClass(){
    //create nav function
    const navigate = useNavigate();
    //declare variables
    const [name, setName] = useState('');
    const [usual_class_name, setUsualClassName] = useState('');
    const [grade, setGrade] = useState(0);
    const [subject, setSubject] = useState('');

    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);

    async function handleNewClass(e){
        e.preventDefault();
        setShowError(false);
        setLoading(true);
        const new_class={
            name: name,
            usual_class_name: usual_class_name,
            grade: grade,
            subject: subject,
        };

        const outcome = await Client.CreateClass(new_class);
        setLoading(false);
        if(outcome){
            navigate('/admin/home');
        }
        else{
            setShowError(true);
        }
    }

    return <>
            <Card>
                <Box margin={10}>
                    <Stack spacing={2}>
                        <Typography variant="h3" component="div">
                            Add New Class
                        </Typography>
                        <div>
                            <TextField
                            id="class-name"
                            label="Name"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setName(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="class-usual-name"
                            label="Usual Name"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setUsualClassName(e.target.value)}}
                            />
                        </div>
                        <div>
                            <InputLabel id="grade-label">Grade</InputLabel>
                            <Select
                                labelId="grade-label"
                                id="grade-select"
                                label="Grade" 
                                value={grade}
                                defaultValue={0}
                                onChange={(e)=>{setGrade(e.target.value)}}
                            >
                                <MenuItem value={8}>8</MenuItem>
                                <MenuItem value={9}>9</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={11}>11</MenuItem>
                                <MenuItem value={12}>12</MenuItem>
                            </Select>
                        </div>
                        <div>
                            <InputLabel id="subject-label">Subject</InputLabel>
                            <Select
                                labelId="subject-label"
                                id="subject-select"
                                label="Subject" 
                                defaultValue=""
                                value={subject}
                                onChange={(e)=>{setSubject(e.target.value)}}
                            >
                                <MenuItem value={"Mathematics"}>Maths</MenuItem>
                                <MenuItem value={"Information Technology"}>IT</MenuItem>
                            </Select>
                        </div>
                
                        <div>
                            <LinearProgress style={{display: loading?'block':'none'}} color="primary"/>
                        </div>
                        <Alert severity="error" className='alert-box' style={{display: showError?'block':'none'}}>
                            <AlertTitle><strong>Error</strong></AlertTitle>
                            Error creating learner. Please refresh page and try again
                        </Alert>
                        <div className="heel">
                            <Button variant="contained" size="large" onClick={handleNewClass}>
                                Submit
                            </Button>
                        </div>
                    </Stack>
                </Box>
            </Card>
            
        </>
}

export default AddClass;