/*
 *
 *async/await implementation
 * 
 */

import Config from '../utils/config';
import axios from 'axios';
const Client = {};
const instance = axios.create({
    baseURL: 'http://localhost:7000/',
    timeout: 15000,
    // headers: {'Authorization': auth.GetToken}
  });


  /*
*
*TUTORS
*
*/

Client.LoginTutor = async (username, pass)=>{
    try{
        const res = await instance.post(Config.network.TUTOR_LOGIN,
            {
                username: username,
                password: pass,
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            console.log('RES', res)
        if(res.status === 200){
            //get token / tutor details and store it
            sessionStorage.setItem("user_token", res.data.token);
            sessionStorage.setItem("user_fullname", res.data.fullname);
            sessionStorage.setItem("user_id", res.data.tutor_id);
            return true;
        }
        else{
            return false;
        }
    }
    catch(error){
        console.log('Login Error: ', error);
        return false;
    }
}
Client.CreateTutorApplication = async (new_tutor)=>{
    try{
        const bearer_token = "bearer " + sessionStorage.getItem("user_token");
        const res = await instance.post(Config.network.TUTOR_APPLY,
            new_tutor
            ,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': bearer_token
                }
            })
        if(res.status === 200){
            return true;
        }
        else{
            return false;
        }
    }
    catch(error){
        console.log('Login Error: ', error);
        return false;
    }
}
Client.CreateTutor = async (new_tutor)=>{
    try{
        const res = await instance.post(Config.network.TUTOR_REGISTER,
            new_tutor
            ,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        if(res.status === 200){
            return true;
        }
        else{
            return false;
        }
    }
    catch(error){
        console.log('Login Error: ', error);
        return false;
    }
}
/*
*
*ADMIN
*
*/

Client.LoginAdmin = async (username, pass)=>{
    try{
        const res = await instance.post(Config.network.ADMIN_LOGIN,
            {
                username: username,
                password: pass,
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            console.log('RES', res)
        if(res.status === 200){
            //get token and store it
            sessionStorage.setItem("user_token", res.data.token);
            sessionStorage.setItem("admin_id", res.data.tutor_id);
            return true;
        }
        else{
            return false;
        }
    }
    catch(error){
        console.log('Login Error: ', error);
        return false;
    }
}
/*
*
*TOKENS
*
*/
Client.VerifyToken = async (auth_token)=>{
    try{
        const res = await instance.post(Config.network.VERIFY_TOKEN,
            {
                token: auth_token,
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        if(res.status === 200){
            return true;
        }
        else{
            return false;
        }
    }
    catch(error){
        console.log('Validation Error: ', error);
        return false;
    }
};

Client.GetToken=()=>{
    const token = sessionStorage.getItem("user_token");
    return token;
};

export default Client