import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import LinearProgress from '@mui/material/LinearProgress';
import { TextField, Alert, AlertTitle, Select, MenuItem, InputLabel} from "@mui/material";

import React from 'react';
import { useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import Client from '../../client/auth'; 

let render = null;

function Login(){
    //create nav function
    const navigate = useNavigate();
    //declare variables
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    //function to process login button click
    async function handleAdminLogin(e){
        e.preventDefault();
        setShowError(false);
        setLoading(true);
        const outcome = await Client.LoginAdmin(username, password);
        setLoading(false);
        if(outcome){
            navigate('/admin/home');
        }
        else{
            setShowError(true);
        }
    }
    //components displayed on screem
    return <div>
        <Alert severity="error" className='alert-box' style={{display: showError?'block':'none'}}>
            <AlertTitle><strong>Error</strong></AlertTitle>
            Credentials provided do not work try again
        </Alert>

        <Grid2 
                container 
                spacing={2}>
                <Grid2 xs={12} md={6}>
        <Card xs={12} md={8}  sx={{minWidth: 350}}>
                <Box margin={10}>
                    <Stack spacing={2}>
                        <Typography variant="h3" component="div">
                            Mihandzu Admin Login
                        </Typography>
                        <div>
                            <LinearProgress style={{display: loading?'block':'none'}} color="primary"/>
                        </div>
                        <div>
                            <TextField
                            id="tutor-username"
                            label="Username"
                            type="text"
                            color="secondary" 
                            value={username}
                            onChange={(e)=>{setUsername(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="tutor-password"
                            label="Password"
                            type="password"
                            color="secondary"
                            value={password}
                            xs={12}
                            onChange={(e)=>{setPassword(e.target.value)}}
                            />
                        </div>
                        <div className="heel">
                            <Button disabled={loading} variant="contained" size="large" onClick={handleAdminLogin}>
                                Log In
                            </Button>
                        </div>
                    </Stack>
                </Box>
            </Card>
        </Grid2>
    </Grid2>
    </div>
}

function AdminAuth() {
    render = <Login/>

    return (
        <div>
            {alert}
            <Box 
                component="form"
                sx={{
                '& .MuiTextField-root': { m: 2, width: '35ch' },
                }}
            noValidate
            autoComplete="off"
            >
                {render}
                <a
                className="App-link"
                href="https://reactjs.org"
                target="_blank"
                rel="noopener noreferrer"
                >
                </a>
            </Box>
        </div>
    )
}

export default AdminAuth;