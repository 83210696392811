import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import { TextField, Alert, AlertTitle, Select, MenuItem, InputLabel, Checkbox, ListItemText, Fab} from "@mui/material";

import AddIcon from '@mui/icons-material/Add';

import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Client from '../../client/learners'; 


function AddLearner(){
    //create nav function
    const navigate = useNavigate();
    //declare variables
    const [fullname, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [cellphone, setCell] = useState('');
    const [grade, setGrade] = useState('');
    const [subject, setSubject] = useState('');
    const [docsSubmitted, setSubmitted] = useState([]);
    const [learnerClass, setClass] = useState('');
    const [classOptions, setClassOptions] = useState([]);
    const [parentFields, setParentFields] = useState([])
    
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);

    function handleDocsSubmitted(e){
        const {
        target: { value },
        } = e;

        const docs = typeof value === 'string' ? value.split(',') : value;
        setSubmitted(docs);
    }

    async function handleNewLearner(e){
        console.log('SAVING....');
        e.preventDefault();
        setShowError(false);
        setLoading(true);
        const new_learner={
            name: fullname,
            surname: surname,
            cellphone: cellphone,
            grade: grade,
            subject: subject,
            docs_submitted: docsSubmitted,
            parents: parentFields,
            class: learnerClass,
        };
        console.log('NEW LEARNER: ', new_learner);
        const outcome = await Client.CreateLearner(new_learner);
        setLoading(false);
        if(outcome){
            const tutor_id = sessionStorage.getItem('user_id');
            if(tutor_id){
                navigate('/tutor/home');
            }
            else{
                navigate('/admin/home');
            }
        }
        else{
            setShowError(true);
        }
    }
    async function handleParentName(index, new_name){
        let parents = [...parentFields];
        parents[index].name = new_name;
        setParentFields(parents);
    }

    async function handleParentSurname(index, new_surname){
        let parents = [...parentFields];
        parents[index].surname = new_surname;
        setParentFields(parents);
    }


    async function handleParentEmail(index, new_email){
        let parents = [...parentFields];
        parents[index].email = new_email;
        setParentFields(parents);
    }


    async function handleParentContact(index, new_contact_number){
        let parents = [...parentFields];
        parents[index].contact_numbers =[{number: new_contact_number, active: true}];
        setParentFields(parents);
    }

    function addParent(){
        let newParent = {name:'',surname:'',email:'', contact_number:[]};
        setParentFields([...parentFields, newParent]);
    }

    //fetch classes
    useEffect(()=>{
        loadClasses()
    }, []);

    const loadClasses = async()=>{
        const classes_data = await Client.GetClass({});
        if(classes_data && classes_data.data){
            const classes = classes_data.data;
            const classList = [];
            classes.forEach((c)=>{
                classList.push({
                    key: c.name,
                    value: c._id,
                });
            });
            setClassOptions([
                {key: 'Select Learners Class', value:''},
                ...classList
            ])
        }
        else{
            //display error
            console.log('EISH. Couldnt load classes');
        }
    }
    return <>
            <Card>
                <Box margin={10}>
                    <Stack spacing={2}>
                        <Typography variant="h3" component="div">
                            Add New Learner
                        </Typography>
                        <div>
                            <TextField
                            id="tutor-name"
                            label="Fullname"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setName(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="tutor-surname"
                            label="Surname"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setSurname(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="tutor-cell"
                            label="Cellphone"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setCell(e.target.value)}}
                            />
                        </div>
                        <div>
                            <InputLabel id="grade-label">Grade</InputLabel>
                            <Select
                                labelId="grade-label"
                                id="grade-select"
                                label="Grade" 
                                value={grade}
                                defaultValue={0}
                                onChange={(e)=>{setGrade(e.target.value)}}
                            >
                                <MenuItem value={8}>8</MenuItem>
                                <MenuItem value={9}>9</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={11}>11</MenuItem>
                                <MenuItem value={12}>12</MenuItem>
                            </Select>
                        </div>
                        <div>
                            <InputLabel id="subject-label">Subject</InputLabel>
                            <Select
                                labelId="subject-label"
                                id="subject-select"
                                label="Subject" 
                                defaultValue=""
                                onChange={(e)=>{setSubject(e.target.value)}}
                            >
                                <MenuItem value={"Mathematics"}>Maths</MenuItem>
                                <MenuItem value={"Information Technology"}>IT</MenuItem>
                            </Select>
                        </div>
                        
                        <div>
                            <InputLabel id="class-label">Class</InputLabel>
                            <Select
                                labelId="class-label"
                                id="class-select"
                                label="Class"
                                onChange={(e)=>{setClass(e.target.value)}}
                            >
                                {classOptions.map((c)=>(
                                <MenuItem key={c.key} value={c.value}>
                                   <ListItemText primary={c.key} />
                                </MenuItem>
                                )
                                )}
                            </Select>
                        </div>
                        <div>
                            <InputLabel id="docs-submitted-label">Docs Submitted</InputLabel>
                            <Select
                                labelId="docs-submitted-label"
                                id="docs-submitted-select"
                                label="Docs Submitted" 
                                multiple
                                defaultValue={""}
                                value={docsSubmitted}
                                onChange={handleDocsSubmitted}
                                renderValue={(selected) => selected.join(', ')}
                            >
                               <MenuItem value={"Indemnity Form"}>
                                    <Checkbox checked={docsSubmitted.indexOf("Indemnity Form") > -1} />
                                    <ListItemText primary={"Indemnity Form"} />
                                </MenuItem>
                                <MenuItem value={"School Report"}>
                                    <Checkbox checked={docsSubmitted.indexOf("School Report") > -1} />
                                    <ListItemText primary={"School Report"} />
                                </MenuItem>
                                <MenuItem value={"Parent Information"}>
                                    <Checkbox checked={docsSubmitted.indexOf("Parent Information") > -1} />
                                    <ListItemText primary={"Parent Information"} />
                                </MenuItem>
                            </Select>
                        </div>

                        <div>
                            {
                                parentFields.map((parent, index)=>{
                                    return(
                                        <div key={`${"parent"}-${index}}`}>
                                            <TextField
                                            label="Name"
                                            type="text"
                                            color="secondary" 
                                            value={parent.name || ''}
                                            onChange={(e)=>{handleParentName(index, e.target.value)}}
                                            />
                                            <TextField
                                            label="Surname"
                                            type="text"
                                            color="secondary" 
                                            value={parent.surname}
                                            onChange={(e)=>{handleParentSurname(index, e.target.value)}}
                                            />
                                            <TextField
                                            label="Email"
                                            type="text"
                                            color="secondary" 
                                            value={parent.email}
                                            onChange={(e)=>{handleParentEmail(index, e.target.value)}}
                                            />
                                            <TextField
                                            label="Contact number"
                                            type="text"
                                            color="secondary" 
                                            value={parent.contact_numbers?parent.contact_numbers[0].number : ''}
                                            onChange={(e)=>{handleParentContact(index, e.target.value)}}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div>
                            <Fab color="primary" aria-label="add" onClick={addParent}>
                                <AddIcon />
                            </Fab>
                            <InputLabel id="docs-submitted-label">
                                Add Parent Details</InputLabel>
                            
                        </div>
                        <div>
                            <LinearProgress style={{display: loading?'block':'none'}} color="primary"/>
                        </div>
                        <Alert severity="error" className='alert-box' style={{display: showError?'block':'none'}}>
                            <AlertTitle><strong>Error</strong></AlertTitle>
                            Error creating learner. Please refresh page and try again
                        </Alert>
                        <div className="heel">
                            <Button variant="contained" size="large" onClick={handleNewLearner}>
                                Submit
                            </Button>
                        </div>
                    </Stack>
                </Box>
            </Card>
            
        </>
}

export default AddLearner;