import logo from './logo.jpeg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import TutorAuth from './components/tutor/auth.js';
import TutorProfile from './components/tutor/profile.js';
import TutorHome from './components/tutor/home.js';
import TutorAttendance from './components/tutor/attendance.js';
import TutorClasses from './components/tutor/classes.js';

import AdminNewTutor from './components/admin/add_tutor.js';
import AdminNewClass from './components/admin/add_class.js';
import AdminHome from './components/admin/home.js';
import AdminAuth from './components/admin/auth.js';
import AdminEagle from './components/admin/eagle.js';
import AdminApproveTutor from './components/admin/approve_tutor.js';


import AddNewLearner from './components/shared/add_learner.js';
import Landing from './components/landing.js';
import UnAuth from './components/shared/unauthorized.js';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Learner Center
          </p>
        </header>
        <Routes>
          <Route path="/" exact element={<Landing/>}/>
          <Route path="/unauthorized" exact element={<UnAuth/>}/>
          {/* Tutor */}
          <Route path="/tutor/auth/:action" exact element={<TutorAuth/>} />
          <Route path="/tutor/auth" exact element={<TutorAuth/>} />
          <Route path="/tutor/home/:status" exact element={<TutorHome/>} />
          <Route path="/tutor/home" exact element={<TutorHome/>} />
          <Route path="/tutor/profile/:action" exact element={<TutorProfile/>} />
          <Route path="/tutor/profile" exact element={<TutorProfile/>} />
          <Route path="/profile/:tutor" exact element={<TutorProfile/>} />
          <Route path="/tutor/attendance/:action" exact element={<TutorAttendance/>} />
          <Route path="/tutor/attendance" exact element={<TutorAttendance/>} />
          <Route path="/tutor/classes" exact element={<TutorClasses/>} />
          <Route path="/tutor/learner/new" exact element={<AddNewLearner/>} />
          {/* Admin */}
          <Route path="/admin/home" exact element={<AdminHome/>} />
          <Route path="/admin/tutor/new" exact element={<AdminNewTutor/>} />
          <Route path="/admin/class/new" exact element={<AdminNewClass/>} />
          <Route path="/admin/learner/new" exact element={<AddNewLearner/>} />
          <Route path="/admin/login" exact element={<AdminAuth/>} />
          <Route path="/admin/exec/eagle" exact element={<AdminEagle/>} />
          <Route path="/admin/tutor/approve" exact element={<AdminApproveTutor/>} />
          {/* Standard */}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
