import Box from '@mui/material/Box';
import {Link} from "@mui/material";
import error_401 from '../../401.jpg';

function Unauthorized(){
    return (
        <div>
        <Box 
            className='background-mihandzu'
            sx={{ display: 'flex', flexDirection: 'column' }}>

            <img src={error_401}/>
            <Link to='/tutor/auth'>Login</Link>
        </Box>
        </div>
    )
}

export default Unauthorized;