import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import React from 'react';
import {Link} from 'react-router-dom';

function Landing(){

    return (
        <div>
        <Box 
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 2, width: '35ch' },
            }}
        noValidate
        autoComplete="off"
        >
            <Card  sx={{minWidth: 350}}>
                <Box margin={10}>
                    <Stack spacing={2}>
                        <Typography variant="h3" component="div">
                            Mihandzu Tutor Portal 
                        </Typography>
                        <Typography variant="h6" component="div">
                            The Mihandzu Tutor Portal is a system designed to help Mihandzu's amazing Tutors help our communities<br/>
                            If you do not have credentials to log in, please contact the Mihandzu Rep at <b>07123456789</b> 
                        </Typography>

                        <div className="heel">
                            <Button variant="contained" size="large">
                                <Link to='/tutor/auth/login'>Log In</Link>
                            </Button>
                        </div>
                        <div className="heel">
                            <Button variant="contained" size="large">
                                <Link to='/tutor/auth/apply'>Apply For Access</Link>
                            </Button>
                        </div>
                    </Stack>
                </Box>
            </Card>

            <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
            >
            </a>
        </Box>
        </div>
    )
}

export default Landing