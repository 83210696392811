
import Config from '../utils/config';
import axios from 'axios';
const Client = {};
const instance = axios.create({
    baseURL: 'http://localhost:7000/',
    timeout: 15000,
    // headers: {'Authorization': auth.GetToken}
  });
/*
 *
 *HOME SCREEN
 *
 */
 
 Client.AdminDashboard = async ()=>{
    const result = {};
     try{
        const bearer_token = "bearer " + sessionStorage.getItem("user_token");
        const res = await instance.get(Config.network.ADMIN_DASH,
             {
                 headers: {
                     'Content-Type': 'application/json',
                     'Authorization': bearer_token,
                 }
             })
        if(res.status === 200){
            result.data = res.data.data;
        }
        else{
            result.error = {error: res.status+' error occured whiles fetching tutor'};
        }
     }
     catch(error){
         console.log('Login Error: ', error);
         result.error = error;
     }
     return result;
 }
 Client.ApproveTutor = async (tutor_id)=>{
    const result = {
        outcome:false
    };
     try{
        const bearer_token = "bearer " + sessionStorage.getItem("user_token");
        const res = await instance.post(Config.network.ADMIN_APPROVE_TUTOR+tutor_id,
            {},
             {
                 headers: {
                     'Content-Type': 'application/json',
                     'Authorization': bearer_token,
                 }
             })
        if(res.status === 200){
            result.outcome = true;
            result.data = res.data.data;
        }
        else{
            result.error = {error: res.status+' error occured whiles fetching tutor'};
        }
     }
     catch(error){
         console.log('Approve Tutor Error: ', error);
         result.error = error;
     }
     return result;
 }
 /*
 *
 *EAGLE ADMIN
 *
 */
 
 Client.CreateAdmin = async (new_admin)=>{
    try{
        const res = await instance.post(Config.network.EAGLE_ADD_ADMIN+Config.security.EAGLE_ACCESS_CODE,
            new_admin
            ,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        );
        if(res.status === 200){
            return true;
        }
        else{
            return false;
        }
    }
    catch(error){
        console.log('Eagle Admin Create Error: ', error);
        return false;
    }
}
 /*
 *
 *TOKENS
 *
 */
 
 Client.GetToken=()=>{
     const token = sessionStorage.getItem("user_token");
     return token;
 };
 
 export default Client