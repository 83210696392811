import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Alert, AlertTitle, CardHeader, CardContent, CardActions, Avatar, LinearProgress } from "@mui/material";
import { green } from '@mui/material/colors';

import {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import admin from '../../client/admin';

function AdminHome(){
    const navigate = useNavigate();

    const [tutor_count, setTutorCount] = useState(0);
    const [tutor_app_count, setTutorAppCount] = useState(0);
    const [class_count, setClassCount] = useState(0);
    const [learner_count, setLearnerCount] = useState(0);
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        loadDashboard()
    }, []);

    const loadDashboard = async()=>{
        setLoading(true);
        const dash_data = await admin.AdminDashboard();
        if(dash_data.data){
            setClassCount(dash_data.data.class_count);
            setLearnerCount(dash_data.data.learner_count);
            setTutorCount(dash_data.data.tutor_count);
            setTutorAppCount(dash_data.data.tutor_app_count);
        }
        setLoading(false);
    }

    return (
        <div>
        <Alert severity="error" className='alert-box' style={{display: showError?'block':'none'}}>
            <AlertTitle>Error</AlertTitle>
            Credentials provided do not work — <strong>try again!</strong>
        </Alert>
        <Typography variant="h3" component="div">
            Admin Dashboard
        </Typography>

        <Box 
            sx={{ display: 'flex', flexDirection: 'column' }}>

            <div style={{display: loading?'block':'none'}}>
                
                <LinearProgress  color="primary"/>

                <Typography variant="h6" component="div">
                    Loading Dashboard...
                </Typography>
            </div>
            <div 
                style={{display: !loading?'block':'none'}}>
            <Grid2 
                container 
                spacing={2}>
                <Grid2 xs={6} md={4}>
                    <Card  sx={{maxWidth: 350}}
                        >
                        <CardHeader
                            title="Tutors"
                            subheader="total number of tutors registered on the platform"
                        />
                        <CardContent style={{ justifyContent: "center", display: "flex" }}>
                            <Avatar sx={{ bgcolor: green[600] }} aria-label="recipe">
                            {tutor_count}
                            </Avatar>
                        </CardContent>

                        <CardActions>
                            <Button size="medium" variant="outlined" href='/admin/tutor/new'>Add Tutor</Button>
                        </CardActions>

                    </Card>
                </Grid2>
                <Grid2 xs={6} md={4}>
                    <Card  sx={{maxWidth: 350}}
                        >
                        <CardHeader
                            title="Tutor Applications"
                            subheader="total number of tutors who have entered details on the platform and need to be approved"
                        />
                        <CardContent style={{ justifyContent: "center", display: "flex" }}>
                            <Avatar sx={{ bgcolor: green[600] }} aria-label="recipe">
                            {tutor_app_count}
                            </Avatar>
                        </CardContent>

                        <CardActions>
                            <Button size="medium" variant="outlined" href='/admin/tutor/approve'>Approve Tutors</Button>
                        </CardActions>

                    </Card>
                </Grid2>
                <Grid2 xs={6} md={4}>
                    <Card  sx={{maxWidth: 350}}>
                        <CardHeader
                            title="Learners"
                            subheader="total number of school learners on the platform"
                        />
                        <CardContent style={{ justifyContent: "center", display: "flex" }}>
                            <Avatar sx={{ bgcolor: green[900] }} aria-label="recipe">
                            {learner_count}
                            </Avatar>
                        </CardContent>

                        <CardActions>
                            <Button size="medium" variant="outlined" href='/admin/learner/new'>Add Learners</Button>
                        </CardActions>
                    </Card>
                </Grid2>

                <Grid2 xs={6} md={4}>
                    <Card  sx={{maxWidth: 350}}>
                        <CardHeader
                            title="Classes"
                            subheader="average number of classes"
                        />
                        <CardContent style={{ justifyContent: "center", display: "flex" }}>
                            <Avatar sx={{ bgcolor: green[300] }} aria-label="recipe">
                            {class_count}
                            </Avatar>
                        </CardContent>

                        <CardActions>
                            <Button size="medium" variant="contained" href='/admin/class/new'>Add Class</Button>
                        </CardActions>
                    </Card>
                </Grid2>
            </Grid2>
        </div>
            <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
            >
            </a>
        </Box>
        </div>
    )
}

export default AdminHome