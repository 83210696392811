import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Alert, AlertTitle, CardHeader, CardContent, LinearProgress } from "@mui/material";

import {useEffect, useState} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import AdminClient from '../../client/admin';
import TutorClient from '../../client/tutor';

function AdminApproveTutors(){
    const navigate = useNavigate();

    const [tutor_applications, setTutorApplications] = useState([]);
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(()=>{
        loadTutorApplications()
    }, []);

    const loadTutorApplications = async()=>{
        setLoading(true);
        //load tutor applications
        let applications = await TutorClient.GetTutorApplications();
        if(applications && applications.data){
            setTutorApplications(applications.data.data);
        }
        setLoading(false);
    }

    const approveTutor = async(e, tutor_id)=>{
        e.preventDefault();
        setShowError(false);
        setShowSuccess(false);
        setLoading(true);
        const res = await AdminClient.ApproveTutor(tutor_id);
        setLoading(false);
        if(res.outcome){
            setShowSuccess(true);
            loadTutorApplications();
        }
        else{
            setShowError(true);
        }
    }

    return (
        <div> 
        <Alert severity="error" className='alert-box' style={{display: showError?'block':'none'}}>
            <AlertTitle>Error</AlertTitle>
            Could not successfully approve Tutor Applications — <strong>try again!</strong>
        </Alert>
        <Alert severity="success" className='alert-box' style={{display: showSuccess?'block':'none'}}>
            <AlertTitle>Success</AlertTitle>
            Tutor successfully approved.  — <strong>try again!</strong>
        </Alert>
        <Typography variant="h3" component="div">
            Tutor Applications
        </Typography>

        <Box 
            sx={{ display: 'flex', flexDirection: 'column' }}>

            <div style={{display: loading?'block':'none'}}>
                
                <LinearProgress  color="primary"/>

                <Typography variant="h6" component="div">
                    Loading Tutor Applications...
                </Typography>
            </div>
            <div 
                style={{display: !loading?'block':'none'}}>
            <Grid2 
                container 
                spacing={2}>

                    {tutor_applications.map((tutor)=>(
                         <Grid2 key={tutor._id} xs={6} md={4}>
                            <Card  sx={{maxWidth: 350}}
                                >
                                <CardHeader
                                    title={tutor.name+" "+tutor.surname}
                                    subheader={tutor.email}
                                />
                                <CardContent style={{ justifyContent: "center", display: tutor.cellphone && tutor.cellphone !== ''?'flex':'none' }}>
                                    {tutor.cellphone}
                                </CardContent>
                                <CardContent style={{ justifyContent: "center", display: tutor.email && tutor.email !== ''?'flex':'none' }}>
                                    {tutor.email}
                                </CardContent>
                                <CardContent style={{ justifyContent: "center", display: tutor.studying && tutor.studying !== ''?'flex':'none' }}>
                                    {tutor.studying}
                                </CardContent>
                                <CardContent style={{ justifyContent: "center", display: tutor.grade_tutoring && tutor.grade_tutoring !== ''?'flex':'none'  }}>
                                    Grade {tutor.grade_tutoring}
                                </CardContent>
                                <CardContent style={{ justifyContent: "center", display: tutor.subject_tutoring && tutor.subject_tutoring !== ''?'flex':'none' }}>
                                    {tutor.subject_tutoring}
                                </CardContent>
                                <CardContent style={{ justifyContent: "center",  display: tutor.university && tutor.university !== ''?'flex':'none'  }}>
                                    {tutor.university}
                                </CardContent>
                                <CardContent style={{ justifyContent: "center",  display: tutor.institution && tutor.institution !== ''?'flex':'none'  }}>
                                    {tutor.institution}
                                </CardContent>
                                <CardContent style={{ justifyContent: "center",  display: tutor.tertiary_level && tutor.tertiary_level !== ''?'flex':'none'  }}>
                                    {tutor.tertiary_level}
                                </CardContent>
                                <CardContent style={{ justifyContent: "center", display: tutor.maths_topics_comfortable && tutor.maths_topics_comfortable !== ''?'flex':'none' }}>
                                    {tutor.maths_topics_comfortable}
                                </CardContent>
                                <CardContent style={{ justifyContent: "center", display: 'flex'}}>
                                    
                                    <div className="heel">
                                        <Button disabled={loading} variant="contained" size="large" onClick={e=>approveTutor(e, tutor._id)}>
                                            Approve
                                        </Button>
                                    </div>
                                </CardContent>
    
                            </Card>
                        </Grid2>
                    ))}
            </Grid2>
            <Grid2>
                <Link to='/admin/home'>Back 2 Dashboard</Link>
            </Grid2>
        </div>
        </Box>
        </div>
    )
}

export default AdminApproveTutors;