import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import { TextField, Alert, AlertTitle,Checkbox, Select, MenuItem, ListItemText, InputLabel, Icon} from "@mui/material";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import Client from '../../client/auth'; 

let render = null;

const mathsTopics = [
    'Whole Numbers',
    'Common Fractions',
    'Integers',
    'Exponents',
    'Numeric & Geometric Patterns',
    'Algeraic Equations',
    'Algeraic Expressions',
    'Graphs',
    'Geometry of Straight Lines',
    'Geometry of 2D Shapes',
    'Transformation Geometry',
    'Functions & Relationships',
    'Area & Permimeter of 2D Shapes',
    'Surface and Volume',
    'Theorem of Pythagoras',
    'Revision',
  ];

function Login(){
    //create nav function
    const navigate = useNavigate();
    //ensure user is logged in
    useEffect(()=>{
        loadScreen();
    }, []);

    const loadScreen = async()=>{
        const tutor_id = sessionStorage.getItem('user_id');
        const user_token = sessionStorage.getItem('user_token');
        if(tutor_id && user_token){
            //verify token
            const valid_token = await Client.VerifyToken(user_token);
            console.log("VALID: ", valid_token)
            if(valid_token){
                navigate('/tutor/home');
            }
        }
    }
    //declare variables
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    //function to process login button click
    async function handleTutorLogin(e){
        e.preventDefault();
        setShowError(false);
        setLoading(true);
        const outcome = await Client.LoginTutor(username, password);
        setLoading(false);
        if(outcome){
            navigate('/tutor/home');
        }
        else{
            setShowError(true);
        }
    }
    //components displayed on screem
    return <div>
        <Alert severity="error" className='alert-box' style={{display: showError?'block':'none'}}>
            <AlertTitle><strong>Error</strong></AlertTitle>
            Credentials provided do not work try again
        </Alert>
        <Card  sx={{minWidth: 350}}>
                <Box margin={10}>
                    <Stack spacing={2}>
                        <Typography variant="h3" component="div">
                            Mihandzu Tutor Login
                        </Typography>
                        <div>
                            <LinearProgress style={{display: loading?'block':'none'}} color="primary"/>
                        </div>
                        <div>
                            <TextField
                            id="tutor-username"
                            label="Username"
                            type="text"
                            color="secondary"
                            value={username}
                            onChange={(e)=>{setUsername(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="tutor-password"
                            label="Password"
                            type="password"
                            color="secondary"
                            value={password}
                            onChange={(e)=>{setPassword(e.target.value)}}
                            />
                        </div>
                        <div className="heel">
                            <Button disabled={loading} variant="contained" size="large" onClick={handleTutorLogin}>
                                Log In
                            </Button>
                        </div>
                        {/* <div>
                            <Link
                                className="App-link"
                                rel="noopener noreferrer"
                                to="/tutor/auth/apply"
                                >
                                    No Login Details? Apply Here
                            </Link>
                        </div> */}
                    </Stack>
                </Box>
            </Card></div>
}

function Register(){
    //create nav function
    const navigate = useNavigate();
    //declare variables
    const [fullname, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [cellphone, setCell] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [university, setUniversity] = useState('');
    const [institution, setInstitution] = useState('');
    const [courseStudying, setStudying] = useState('');
    const [tertiaryLevel, setTertiaryLevel] = useState('');
    const [gradeTutoring, setGradeTutoring] = useState('');
    const [subjectTutoring, setSubjectTutoring] = useState('');
    const [mathsTopicsComfortable, setMathTopicsComfortable] = useState([]);
    const [showError, setShowError] = useState(false);
    const [txtError, setErrorText] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [loading, setLoading] = useState(false);


    function handleMathsComfortable(e){
        const {
        target: { value },
        } = e;

        const topics = typeof value === 'string' ? value.split(',') : value;
        setMathTopicsComfortable(topics);
    }

    async function handleTutorRegistration(e){
        e.preventDefault();
        setShowError(false);
        if(email.length<=0 || fullname.length<=0){
            setShowError(true);
            setErrorText("Email and Name are required!");
            return;
        }
        setLoading(true);
        const apply_tutor={
            name: fullname,
            surname: surname,
            cellphone: cellphone,
            email: email,
            address: address,
            university: university,
            institution: institution,
            studying: courseStudying,
            tertiary_level: tertiaryLevel,
            grade_tutoring: gradeTutoring,
            subject_tutoring: subjectTutoring,
            maths_topics_comfortable: mathsTopicsComfortable,
        };

        const outcome = await Client.CreateTutorApplication(apply_tutor);
        setLoading(false);
        if(outcome){
            setShowSuccess(true);
        }
        else{
            setShowError(true);    
            setErrorText("Error processing your application. Please refresh page and try again");
        }
    }
    
    return <>
            <Card style={{display: !showSuccess?'block':'none'}}  sx={{minWidth: 350}}>
                <Box margin={10}>
                    <Stack spacing={2}>
                        <Typography variant="h3" component="div">
                            Mihandzu Tutor Application
                        </Typography>
                        <div>
                            <TextField
                            id="tutor-name"
                            label="Fullname"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setName(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="tutor-surname"
                            label="Surname"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setSurname(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="tutor-cell"
                            label="Cellphone"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setCell(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="tutor-email"
                            label="Email"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setEmail(e.target.value)}}
                            />
                        </div>
                        <div>
                            <TextField
                            id="tutor-address"
                            label="Address"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setAddress(e.target.value)}}
                            />
                        </div>
                        <div>
                            <InputLabel id="grade-tutoring-label">Grade Tutoring</InputLabel>
                            <Select
                                labelId="grade-tutoring-label"
                                id="grade-tutoring-select"
                                label="Grade Tutoring" 
                                value={gradeTutoring}
                                defaultValue={0}
                                onChange={(e)=>{setGradeTutoring(e.target.value)}}
                            >
                                <MenuItem value={8}>8</MenuItem>
                                <MenuItem value={9}>9</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={11}>11</MenuItem>
                                <MenuItem value={12}>12</MenuItem>
                            </Select>
                        </div>
                        <div>
                            <InputLabel id="subject-tutoring-label">Subject Tutoring</InputLabel>
                            <Select
                                labelId="subject-tutoring-label"
                                id="subject-tutoring-select"
                                label="Subject Tutoring" 
                                defaultValue=""
                                onChange={(e)=>{setSubjectTutoring(e.target.value)}}
                            >
                                <MenuItem value={"Mathematics"}>Maths</MenuItem>
                                <MenuItem value={"Information Technology"}>IT</MenuItem>
                            </Select>
                        </div>
                        
                        <div>
                            <InputLabel id="topics-comfortable-label">Maths Topics Comfortable</InputLabel>
                            <Select
                                labelId="topics-comfortable-label"
                                id="topics-comfortable-select"
                                label="Maths Topics Comfortable" 
                                multiple
                                defaultValue={""}
                                value={mathsTopicsComfortable}
                                onChange={handleMathsComfortable}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {mathsTopics.map((topic) => (
                                    <MenuItem key={topic} value={topic}>
                                        <Checkbox checked={mathsTopicsComfortable.indexOf(topic) > -1} />
                                        <ListItemText primary={topic} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>

                        <div>
                        <InputLabel id="university-label">Studying @</InputLabel>
                            <Select
                                labelId="university-label"
                                id="university-select"
                                label="University" 
                                defaultValue={""}
                                onChange={(e)=>{setUniversity(e.target.value)}}
                            >
                                <MenuItem value={"UJ APK"}>UJ APK</MenuItem>
                                <MenuItem value={"UJ ABP"}>UJ APB</MenuItem>
                                <MenuItem value={"UJ DFC"}>UJ DFC</MenuItem>
                                <MenuItem value={"Wits University"}>Wits University</MenuItem>
                                <MenuItem value={"UP"}>University of Pretoria</MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                            </Select>
                            <TextField
                            id="tutor-other-uni"
                            label="Other Institution"
                            type="text"
                            color="secondary" 
                            style={{display: 'none'}}
                            onChange={(e)=>{setInstitution(e.target.value)}}
                            />
                        </div>

                        <div>
                            <TextField
                            id="tutor-studying"
                            label="Course Studying"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setStudying(e.target.value)}}
                            />
                        </div>


                        <div>
                            <TextField
                            id="tutor-tertiary-level"
                            label="Tertiary Level (Diploma, Degree, Honors, ETC"
                            type="text"
                            color="secondary" 
                            onChange={(e)=>{setTertiaryLevel(e.target.value)}}
                            />
                        </div>

                        <div>
                            <LinearProgress style={{display: loading?'block':'none'}} color="primary"/>
                        </div>
                        <Alert severity="error" className='alert-box' style={{display: showError?'block':'none'}}>
                            <AlertTitle><strong>Error</strong></AlertTitle>
                            {txtError}
                        </Alert>
                        <div className="heel">
                            <Button variant="contained" size="large" onClick={handleTutorRegistration}>
                                Submit
                            </Button>
                        </div>
                    </Stack>
                </Box>
            </Card>
            <Card style={{display: showSuccess?'block':'none'}} sx={{minWidth: 350}}>
                <Box margin={10}>
                    <Stack spacing={2}>
                        <Typography variant="h3" component="div">
                            Application Submitted 
                        </Typography>
                        <div>
                                <CheckCircleOutlineIcon sx={{ fontSize: 175 }} color="#2A743C"/>
                        </div>

                        <Typography variant="h6" component="div">
                            Please contact the Mihandzu Administrator to approve your application. If you cannot reach them on this number send a message on the Mihandzu Tutoring Team whatsapp group (0712345678) 
                        </Typography>
                    </Stack>
                </Box>
            </Card>
        </>
}

function TutorAuth() {
    const params = useParams();
    const action = params.action;
    if(action==="login"){
        render = <Login/>
    } 
    else{
        render = <Register/>
    }
    return (
        <div>
            {alert}
            <Box 
                component="form"
                sx={{
                '& .MuiTextField-root': { m: 2, width: '35ch' },
                }}
            noValidate
            autoComplete="off"
            >
                {render}
                <a
                className="App-link"
                href="https://reactjs.org"
                target="_blank"
                rel="noopener noreferrer"
                >
                </a>
            </Box>
        </div>
    )
}

export default TutorAuth;